import { gql } from "@apollo/client";

const GET_SLICES_FOR_USER = gql`
  query getSlicesForUser {
    slicesForUser {
      name
      currentAmount
      id
      targetDate
      targetAmount
      createdAt
      updatedAt
      envelopeType
      spending
      notes
      color
      status
      rules {
        id
        name
        ruleCategory
        active
        ruleConditions {
          id
          field
          operator
          value
        }
        ruleActions {
          id
          actionType
          parameters
        }
        ruleSchedule {
          id
          interval
          nextRun
        }
      }
    }
  }
`;

export { GET_SLICES_FOR_USER };
