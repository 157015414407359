import React, { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "./ui/Dialog";
import { SliceForm } from "./SliceForm";
import { Button } from "./ui/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import { PencilIcon } from "lucide-react";
import { cn } from "../lib/utils";

const CreateSliceModal = ({ setSelectedSlice, selectedSlice }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isEditMode = selectedSlice !== null;

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            variant="default"
            onClick={() => {
              setOpen(true);
            }}
            className={cn(
              !isEditMode
                ? "flex items-center gap-2 justify-center whitespace-nowrap font-medium transition-colors duration-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 border border-gray-200 shadow-sm rounded-md px-2.5 text-md text-white hover:shadow-sm"
                : "flex items-center gap-2"
            )}
          >
            {isEditMode ? (
              <PencilIcon className="h-4 w-4" />
            ) : (
              <PlusIcon className="h-4 w-4" />
            )}
            {/* <span className="text-md">{isEditMode ? "Edit" : "Add"} Slice</span> */}
          </Button>
        </DialogTrigger>
        <DialogContent className="bg-white sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {isEditMode ? "Edit Slice" : "Create A New Slice"}
            </DialogTitle>
            <DialogDescription className="text-gray-700">
              {isEditMode
                ? "Edit the details of the slice."
                : "For debt, expenses, goals, or protected funds."}
            </DialogDescription>
          </DialogHeader>
          <SliceForm
            setOpen={setOpen}
            setSelectedSlice={setSelectedSlice}
            isEditMode={isEditMode}
            selectedSlice={selectedSlice}
          />
        </DialogContent>
      </Dialog>
    );
  }
};

CreateSliceModal.propTypes = {
  setSelectedSlice: PropTypes.func,
  selectedSlice: PropTypes.object,
};

export default CreateSliceModal;
