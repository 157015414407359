import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_RULES_FOR_USER } from "../queries/ruleQueries";
import { DELETE_RULE } from "../mutations/ruleMutations";
import { Button } from "./ui/Button";
import DataTable from "./data-table";
import { ruleColumns } from "./data-table/ruleColumns";
import { Spinner } from "./ui/Spinner";
import RuleForm from "./RuleForm";
import RuleDetails from "./RuleDetails";
import { Separator } from "./ui/Separator";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/Dialog";

const RulesHome = () => {
  const [selectedRule, setSelectedRule] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { data, loading, error, refetch } = useQuery(GET_RULES_FOR_USER);
  const [deleteRule] = useMutation(DELETE_RULE, {
    onCompleted: () => {
      setSelectedRule(null);
      refetch();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleDeleteRule = (ruleId) => {
    deleteRule({
      variables: { id: ruleId },
    });
  };

  const handleRowClick = (rule) => {
    setSelectedRule(rule);
    setIsDetailsModalOpen(true);
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-full w-full">
        <Spinner />
      </div>
    );
  if (error)
    return (
      <div className="flex items-center justify-center h-full w-full">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Rules</h2>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2">
                  <Button
                    onClick={() => {
                      setSelectedRule(null);
                      setIsFormOpen(true);
                    }}
                  >
                    Create Rule
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Separator />
          <div className="p-4">
            <DataTable
              columns={ruleColumns}
              data={data.rulesForUser}
              onRowClick={handleRowClick}
              onDeleteRule={handleDeleteRule}
            />
          </div>
        </div>
        <Dialog open={isDetailsModalOpen} onOpenChange={setIsDetailsModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Rule Details</DialogTitle>
            </DialogHeader>
            {selectedRule && (
              <RuleDetails
                rule={selectedRule}
                onDelete={handleDeleteRule}
                onEdit={() => {
                  setIsDetailsModalOpen(false);
                  setIsFormOpen(true);
                  setSelectedRule(selectedRule);
                }}
                onClose={() => {
                  setIsDetailsModalOpen(false);
                  setSelectedRule(null);
                }}
              />
            )}
          </DialogContent>
        </Dialog>
        <RuleForm
          rule={selectedRule}
          onComplete={() => {
            setIsFormOpen(false);
            setSelectedRule(null);
            refetch();
          }}
          onClose={() => setIsFormOpen(false)}
          isOpen={isFormOpen}
          isEditMode={selectedRule !== null}
        />
      </div>
    </div>
  );
};

export default RulesHome;
