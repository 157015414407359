import { gql } from "@apollo/client";

const GET_RULES_FOR_USER = gql`
  query getRulesForUser {
    rulesForUser {
      id
      name
      ruleCategory
      active
      ruleConditions {
        id
        field
        operator
        value
      }
      ruleActions {
        id
        actionType
        parameters
      }
      ruleSchedule {
        id
        interval
        nextRun
      }
    }
  }
`;

export { GET_RULES_FOR_USER };
