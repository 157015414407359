import React from "react";
import PropTypes from "prop-types";
import { Button } from "./ui/Button";
import { Separator } from "./ui/Separator";
import { formatDate } from "../lib/utils";
import { Badge } from "./ui/Badge";

const RuleDetails = ({ rule, onDelete, onEdit, onClose }) => {
  const detailRows = [
    { label: "Name", value: rule.name },
    { label: "Category", value: rule.ruleCategory },
    { label: "Active", value: rule.active ? "Yes" : "No" },
    {
      label: "Conditions",
      value: (
        <ul>
          {rule.ruleConditions.map((condition, index) => (
            <li key={index}>
              {condition.field} {condition.operator} {condition.value}
            </li>
          ))}
        </ul>
      ),
    },
    {
      label: "Actions",
      value: (
        <ul>
          {rule.ruleActions.map((action, index) => (
            <li key={index}>
              {action.actionType}: {JSON.stringify(action.parameters)}
            </li>
          ))}
        </ul>
      ),
    },
    {
      label: "Schedule",
      value: (
        <>
          <p>Interval: {rule.ruleSchedule.interval}</p>
          <p>Next run: {formatDate(rule.ruleSchedule.nextRun)}</p>
        </>
      ),
    },
  ];

  return (
    <div className="w-full max-w-3xl mx-auto bg-white border border-gray-200 rounded-lg overflow-hidden">
      <div className="flex flex-row items-center justify-between p-6 bg-gray-100">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-bold text-gray-800">{rule.name}</h2>
        </div>
        <Badge variant={rule.active ? "default" : "secondary"}>
          {rule.active ? "Active" : "Inactive"}
        </Badge>
      </div>
      <div className="p-6">
        <div className="grid gap-4">
          <h3 className="font-semibold text-lg text-gray-700">Details</h3>
          <div className="grid gap-2">
            {detailRows.map((row, index) => (
              <React.Fragment key={index}>
                <div className="flex items-center justify-between py-2">
                  <span className="text-gray-600">{row.label}</span>
                  <span className="font-medium text-gray-800">{row.value}</span>
                </div>
                {index < detailRows.length - 1 && <Separator />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <Separator className="my-4" />
        <div className="flex justify-end space-x-2 mt-6">
          <Button onClick={onClose} variant="outline">
            Close
          </Button>
          <Button onClick={onEdit} variant="default">
            Edit
          </Button>
          <Button onClick={() => onDelete(rule.id)} variant="destructive">
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

RuleDetails.propTypes = {
  rule: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RuleDetails;
