import React from "react";
import { ScrollArea } from "./ui/ScrollArea";
import PropTypes from "prop-types";
import {
  calculateSlicePercentage,
  cn,
  getEnvelopeTypeText,
  numberWithCommas,
} from "../lib/utils";
import { Badge } from "./ui/Badge";
import { ProgressBar } from "@tremor/react";

const SlicesList = ({ slices, selectedSlice, setSelectedSlice }) => {
  return (
    <ScrollArea className="h-screen">
      <div className="flex flex-col gap-4 p-4 pt-0">
        {slices.map((slice) => (
          <button
            key={slice.id}
            className={cn(
              "flex flex-col items-start gap-4 rounded-lg border p-3 text-left text-sm transition-all hover:bg-gray-200",
              selectedSlice === slice.id && "bg-muted"
            )}
            onClick={() => setSelectedSlice(slice)}
          >
            <div className="flex w-full flex-col gap-3">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{slice.name}</div>
                </div>
                <Badge variant={slice.envelopeType} className={cn("ml-auto")}>
                  {getEnvelopeTypeText(slice.envelopeType)}
                </Badge>
              </div>
              <div className="line-clamp-2 text-xs text-muted-foreground">
                {slice.notes.substring(0, 100)}
              </div>
              <ProgressBar
                value={calculateSlicePercentage(slice)}
                className="w-full rounded-md"
                color={slice.color}
              />
              <span>
                {"$"}
                {numberWithCommas((slice.currentAmount / 100).toFixed(2))}{" "}
                stashed / {"$"}
                {numberWithCommas(
                  (
                    slice.targetAmount / 100 -
                    slice.currentAmount / 100
                  ).toFixed(2)
                )}{" "}
                remaining
              </span>
            </div>
          </button>
        ))}
      </div>
    </ScrollArea>
  );
};

SlicesList.propTypes = {
  slices: PropTypes.array.isRequired,
  selectedSlice: PropTypes.object,
  setSelectedSlice: PropTypes.func,
};

export default SlicesList;
