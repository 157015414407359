import React from "react";
import { Table } from "@tanstack/react-table";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { Cross2Icon } from "@radix-ui/react-icons";
import DataTableFacetedFilter from "./DataTableFacetedFilter";
import { DataTableViewOptions } from "./DataTableViewOptions";
import BulkActionMenu from "../BulkActionMenu";
import { Transaction } from "../../lib/transactionSchema";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  accountOptions: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  sliceOptions: {
    label: string;
    value: string;
  }[];
  categoryOptions: {
    label: string;
    value: string;
  }[];
  showingRecentTransactions: boolean;
  selectedRows: Transaction[];
  onBulkDelete?: (transactions: Transaction[]) => void;
  onBulkAssign?: (transactions: Transaction[], sliceId: string) => void;
  onBulkUnassign?: (transactions: Transaction[]) => void;
  onBulkChangeCategory?: (
    transactions: Transaction[],
    categoryId: string
  ) => void;
  onBulkChangeDescription?: (
    transactions: Transaction[],
    description: string
  ) => void;
  slices: { id: string; name: string }[];
  categories: { id: string; name: string }[];
}

function DataTableToolbar<TData>({
  table,
  accountOptions,
  sliceOptions,
  categoryOptions,
  showingRecentTransactions,
  selectedRows,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
  categories,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Search transactions..."
          value={
            (table.getColumn("transactionName")?.getFilterValue() as string) ??
            ""
          }
          onChange={(e) => {
            table.getColumn("transactionName")?.setFilterValue(e.target.value);
          }}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {!showingRecentTransactions && table.getColumn("accountName") && (
          <DataTableFacetedFilter
            column={table.getColumn("accountName")}
            title="Account"
            options={accountOptions}
          />
        )}
        {!showingRecentTransactions && table.getColumn("assignedSliceName") && (
          <DataTableFacetedFilter
            column={table.getColumn("assignedSliceName")}
            title="Slice"
            options={sliceOptions}
          />
        )}
        {!showingRecentTransactions && table.getColumn("category") && (
          <DataTableFacetedFilter
            column={table.getColumn("category")}
            title="Category"
            options={categoryOptions}
          />
        )}

        {isFiltered && !showingRecentTransactions && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <BulkActionMenu
          selectedRows={selectedRows}
          onBulkDelete={onBulkDelete!}
          onBulkAssign={onBulkAssign!}
          onBulkUnassign={onBulkUnassign!}
          onBulkChangeCategory={onBulkChangeCategory!}
          onBulkChangeDescription={onBulkChangeDescription!}
          slices={slices}
          categories={categories}
        />
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}

export default DataTableToolbar;
