import React, { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Button } from "./ui/Button";
import PropTypes from "prop-types";
import { TrashIcon } from "lucide-react";
import {
  AlertDialog,
  AlertDialogActionDestructive,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/AlertDialog";
import { useMutation } from "@apollo/client";
import { DELETE_SLICE } from "../mutations/sliceMutations";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import toast from "react-hot-toast";

const DeleteSliceModal = ({ setSelectedSlice, selectedSlice, sliceName }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [deleteSlice, { data, loading, error }] = useMutation(DELETE_SLICE, {
    refetchQueries: [
      {
        query: GET_SLICES_FOR_USER,
      },
    ],
  });

  const handleDeleteSlice = () => {
    deleteSlice({
      variables: {
        id: selectedSlice.id,
      },
    });
  };

  useEffect(() => {
    if (data && data.deleteSlice.success) {
      setSelectedSlice(null);
      toast.success("Slice deleted!", { duration: 5000 });
      setOpen(false);
    }

    if (error) {
      toast.error("Something went wrong! Please try again");
    }
  }, [data, error, setSelectedSlice]);

  if (!isDesktop) return null;

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="destructive"
          onClick={() => setOpen(true)}
          className="flex items-center gap-2"
        >
          <TrashIcon className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="bg-white sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            <span>
              This action cannot be undone. The slice{" "}
              <strong>&ldquo;{sliceName}&ldquo;</strong> will be permanently
              deleted.
            </span>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogActionDestructive
            onClick={handleDeleteSlice}
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete Slice"}
          </AlertDialogActionDestructive>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

DeleteSliceModal.propTypes = {
  setSelectedSlice: PropTypes.func,
  selectedSlice: PropTypes.object,
  sliceName: PropTypes.string,
};

export default DeleteSliceModal;
