import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import CreateSliceModal from "./CreateSliceModal";
import SlicesList from "./SlicesList";
import { Separator } from "./ui/Separator";
import { Input } from "./ui/Input";
import SliceDetails from "./SliceDetails";
import { Spinner } from "./ui/Spinner";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import { GET_ACCOUNTS_FOR_USER } from "../queries/plaidQueries";
import { getCategoryOptions, getSliceOptions } from "../lib/utils";

const SlicesHome = () => {
  const [selectedSlice, setSelectedSlice] = useState(null);
  const [categories, setCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { loading: accountsLoading } = useQuery(GET_ACCOUNTS_FOR_USER, {
    onCompleted: (data) => {
      setAccounts(data.accountsForUser);
    },
  });

  const { loading: slicesLoading, data: slicesData } = useQuery(
    GET_SLICES_FOR_USER,
    {
      fetchPolicy: "network-only",
    }
  );

  const { loading: categoriesLoading } = useQuery(GET_CATEGORIES, {
    onCompleted: (data) => {
      setCategories(data.categories);
    },
  });

  const slices = slicesData ? slicesData.slicesForUser : [];

  const filteredSlices = slices.filter(
    (slice) =>
      slice.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      slice.envelopeType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (slicesLoading || categoriesLoading || accountsLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-row h-full">
      <div className="flex py-3 lg:h-full lg:inset-y-0 lg:z-50 lg:flex-col justify-between lg:w-1/3 border-r border-cool-grey-200">
        <div className="flex flex-col">
          <div className="flex items-center px-4 py-2">
            <h1 className="text-xl font-semibold">Slices</h1>
            <div className="ml-auto">
              <CreateSliceModal
                setSelectedSlice={setSelectedSlice}
                selectedSlice={null}
              />
            </div>
          </div>
          <Separator />
          {slices.length > 0 && (
            <div className="">
              <form>
                <div className="relative">
                  <div className="px-4 py-4">
                    <Input
                      placeholder="Search by name or type"
                      className="w-full"
                      type="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
          <div className="m-0">
            <div className="flex flex-col w-full h-full overflow-y-auto space-y-4">
              {filteredSlices.length === 0 && (
                <div className="flex flex-row items-center justify-center text-center">
                  {searchTerm
                    ? "No slices found matching your search."
                    : 'No slices found. Click the "Add Slice" button to begin your budgeting journey! 🚀'}
                </div>
              )}
              {slices.length > 0 && (
                <SlicesList
                  slices={filteredSlices}
                  selectedSlice={selectedSlice}
                  setSelectedSlice={setSelectedSlice}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 h-full">
        <SliceDetails
          slice={selectedSlice}
          setSlice={setSelectedSlice}
          sliceOptions={getSliceOptions(slices)}
          categories={categories}
          accounts={accounts}
          categoryOptions={getCategoryOptions(categories)}
          slices={slices}
        />
      </div>
    </div>
  );
};

export default SlicesHome;
