// app/javascript/components/SliceTransactionsTable.jsx
import React from "react";
import DataTable from "./data-table";
import { transactionColumns } from "./data-table/transactionColumns";
import { getAccountOptions } from "../lib/utils";
import PropTypes from "prop-types";

const SliceTransactionsTable = ({
  sliceOptions,
  categories,
  accounts,
  categoryOptions,
  onRowClick,
  onEditTransaction,
  transactions,
  refetch,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
}) => {
  // Filter out the 'assignedSliceName' column
  const filteredColumns = transactionColumns.filter(
    (column) =>
      column.accessorKey !== "assignedSliceName" ||
      !transactionColumns.some((col) => col.accessorKey === "assignedSliceName")
  );

  return (
    <DataTable
      columns={filteredColumns}
      data={transactions}
      accountOptions={getAccountOptions(accounts)}
      sliceOptions={sliceOptions}
      refreshTransactions={refetch}
      showingRecentTransactions={false}
      categories={categories}
      hiddenColumns={["assignedSliceName"]}
      categoryOptions={categoryOptions}
      onRowClick={onRowClick}
      onEditTransaction={onEditTransaction}
      onBulkDelete={onBulkDelete}
      onBulkAssign={onBulkAssign}
      onBulkUnassign={onBulkUnassign}
      onBulkChangeCategory={onBulkChangeCategory}
      onBulkChangeDescription={onBulkChangeDescription}
      slices={slices}
    />
  );
};

SliceTransactionsTable.propTypes = {
  sliceOptions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func.isRequired,
  onBulkUnassign: PropTypes.func.isRequired,
  onBulkChangeCategory: PropTypes.func.isRequired,
  onBulkChangeDescription: PropTypes.func.isRequired,
  slices: PropTypes.array.isRequired,
};

export default SliceTransactionsTable;
