import React, { useCallback, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useMutation } from "@apollo/client";
import {
  EXCHANGE_PUBLIC_TOKEN,
  CREATE_PLAID_LINK_TOKEN,
  SYNC_PLAID_ACCOUNTS,
  SYNC_PLAID_TRANSACTIONS,
} from "../mutations/plaidMutations";
import { Button } from "./ui/Button";
import PropTypes from "prop-types";
import { Spinner } from "./ui/Spinner";

const PlaidLink = ({
  needPlaidReconnection,
  setModalOpen,
  onSyncComplete,
  refetchPlaidStatus,
}) => {
  const [createPlaidLinkToken, { data: linkTokenData }] = useMutation(
    CREATE_PLAID_LINK_TOKEN
  );
  const [exchangePublicToken, { loading: loadingExchangePublicToken }] =
    useMutation(EXCHANGE_PUBLIC_TOKEN);
  const [syncPlaidAccounts, { loading: loadingSyncAccounts }] =
    useMutation(SYNC_PLAID_ACCOUNTS);
  const [syncPlaidTransactions, { loading: loadingSyncTransactions }] =
    useMutation(SYNC_PLAID_TRANSACTIONS);
  const [isSyncing, setIsSyncing] = useState(false);

  const onSuccess = useCallback(
    async (public_token) => {
      setIsSyncing(true);
      if (!needPlaidReconnection) {
        await exchangePublicToken({ variables: { publicToken: public_token } });
      }
      await syncPlaidAccounts();
      await syncPlaidTransactions();
      setIsSyncing(false);
      setModalOpen(false);
      if (onSyncComplete) {
        onSyncComplete();
      }
      await refetchPlaidStatus();
    },
    [
      exchangePublicToken,
      syncPlaidAccounts,
      syncPlaidTransactions,
      onSyncComplete,
    ]
  );

  useEffect(() => {
    createPlaidLinkToken();
  }, [createPlaidLinkToken]);

  const onExit = useCallback(() => {
    console.log("user exited");
  });

  let isOauth = false;
  const config = {
    token: linkTokenData?.createPlaidLinkToken?.linkToken,
    onSuccess,
    onExit,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    isOauth = true;
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  if (
    isSyncing ||
    loadingExchangePublicToken ||
    loadingSyncAccounts ||
    loadingSyncTransactions
  ) {
    return (
      <div className="flex flex-col items-center">
        <Spinner />
        <p className="mt-2 text-center font-semibold">
          {loadingExchangePublicToken && "Connecting to Plaid..."}
          {loadingSyncAccounts && "Syncing accounts..."}
          {loadingSyncTransactions && "Syncing transactions..."}
          {isSyncing &&
            !loadingExchangePublicToken &&
            !loadingSyncAccounts &&
            !loadingSyncTransactions &&
            "Syncing data..."}
        </p>
      </div>
    );
  }

  return (
    <Button variant="default" onClick={() => open()} disabled={!ready}>
      {needPlaidReconnection ? "Reconnect" : "Add New Account"} with Plaid
    </Button>
  );
};

PlaidLink.propTypes = {
  needPlaidReconnection: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  onSyncComplete: PropTypes.func,
  refetchPlaidStatus: PropTypes.func,
};

export default PlaidLink;
