import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "./ui/Separator";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Button } from "./ui/Button";
import CreateSliceModal from "./CreateSliceModal";
import DeleteSliceModal from "./DeleteSliceModal";
import { ArrowLeftRightIcon } from "lucide-react";
import {
  calculateContributionPerDay,
  calculateHowManyDaysTillTargetDate,
  calculateSlicePercentage,
  getEnvelopeTypeText,
  numberWithCommas,
  serializeTransactions,
} from "../lib/utils";
import { ProgressBar } from "@tremor/react";
import SliceTransactionsTable from "./SliceTransactionsTable";
import TransactionDetailsView from "./TransactionDetailsView";
import { TransactionForm } from "./EditTransactionForm";
import toast from "react-hot-toast";
import { GET_TRANSACTIONS_FOR_SLICE } from "../queries/transactionQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Spinner } from "./ui/Spinner";
import {
  BULK_ASSIGN_TRANSACTIONS_TO_SLICE,
  BULK_CHANGE_TRANSACTION_CATEGORY,
  BULK_CHANGE_TRANSACTION_DESCRIPTION,
  BULK_DELETE_TRANSACTIONS,
  BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE,
} from "../mutations/transactionMutations";

const SliceDetails = ({
  slice,
  setSlice,
  sliceOptions,
  categories,
  accounts,
  categoryOptions,
  slices,
}) => {
  if (!slice) {
    return (
      <div className="flex flex-col flex-1 overflow-hidden p-8 h-full text-center items-center justify-center">
        <p className="text-lg font-medium">
          Select a slice to view the details
        </p>
      </div>
    );
  }

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const [getTransactionsForSlice, { loading, error, data, refetch }] =
    useLazyQuery(GET_TRANSACTIONS_FOR_SLICE, {
      variables: { sliceId: slice?.id },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (slice?.id) {
      getTransactionsForSlice({ variables: { sliceId: slice.id } });
    }
  }, [slice]);

  const [bulkDeleteTransactions] = useMutation(BULK_DELETE_TRANSACTIONS);
  const [bulkAssignTransactionsToSlice] = useMutation(
    BULK_ASSIGN_TRANSACTIONS_TO_SLICE
  );
  const [bulkUnassignTransactionsFromSlice] = useMutation(
    BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE
  );
  const [bulkChangeTransactionCategory] = useMutation(
    BULK_CHANGE_TRANSACTION_CATEGORY
  );
  const [bulkChangeTransactionDescription] = useMutation(
    BULK_CHANGE_TRANSACTION_DESCRIPTION
  );

  useEffect(() => {
    if (data) {
      setTransactions(
        serializeTransactions(data.getTransactionsForSlice, accounts)
      );
    }
  }, [data, accounts]);

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsEditing(true);
  };

  const handleBulkDelete = async (transactions) => {
    try {
      const result = await bulkDeleteTransactions({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkDeleteTransactions.success) {
        toast.success(`Deleted ${transactions.length} transactions`);
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkDeleteTransactions.message);
      }
    } catch (error) {
      toast.error("Failed to delete transactions");
    }
  };

  const handleBulkAssign = async (transactions, sliceId) => {
    try {
      const result = await bulkAssignTransactionsToSlice({
        variables: { ids: transactions.map((t) => t.id), sliceId },
      });
      if (result.data.bulkAssignTransactionsToSlice.success) {
        toast.success(`Assigned ${transactions.length} transactions to slice`);
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkAssignTransactionsToSlice.message);
      }
    } catch (error) {
      toast.error("Failed to assign transactions to slice");
    }
  };

  const handleBulkUnassign = async (transactions) => {
    try {
      const result = await bulkUnassignTransactionsFromSlice({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkUnassignTransactionsFromSlice.success) {
        toast.success(
          `Unassigned ${transactions.length} transactions from slice`
        );
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkUnassignTransactionsFromSlice.message);
      }
    } catch (error) {
      toast.error("Failed to unassign transactions from slice");
    }
  };

  const handleBulkChangeCategory = async (transactions, categoryId) => {
    try {
      const result = await bulkChangeTransactionCategory({
        variables: { ids: transactions.map((t) => t.id), categoryId },
      });
      if (result.data.bulkChangeTransactionCategory.success) {
        toast.success(
          `Changed category for ${transactions.length} transactions`
        );
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkChangeTransactionCategory.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction categories");
    }
  };

  const handleBulkChangeDescription = async (transactions, description) => {
    try {
      const result = await bulkChangeTransactionDescription({
        variables: { ids: transactions.map((t) => t.id), description },
      });
      if (result.data.bulkChangeTransactionDescription.success) {
        toast.success(
          `Changed description for ${transactions.length} transactions`
        );
        getTransactionsForSlice({ variables: { sliceId: slice.id } });
      } else {
        toast.error(result.data.bulkChangeTransactionDescription.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction descriptions");
    }
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error loading transactions</p>;

  return (
    <div className="flex flex-col p-4">
      {/* Slice details */}
      {slice && (
        <Card className="flex flex-col flex-1 overflow-hidden mt-4">
          <CardHeader className="flex flex-row items-start p-4 bg-gray-100/50">
            <div className="grid gap-1 w-1/2">
              <CardTitle className="flex flex-row items-center gap-2">
                <span className="text-lg">{slice.name}</span>
                <div className="flex flex-row items-center gap-2">
                  {slice.status ? (
                    <span className="text-sm text-green-500">On Track</span>
                  ) : (
                    <span className="text-sm text-red-500">Off Track</span>
                  )}
                </div>
              </CardTitle>
              <div className="flex flex-col gap-2">
                <div className="text-sm">{slice.notes}</div>
                <div className="text-md font-semibold">
                  Due: {new Date(slice.targetDate).toLocaleDateString()}
                </div>
              </div>
            </div>
            <div className="ml-auto flex items-center gap-1">
              <Button variant="outline">
                <ArrowLeftRightIcon className="w-4 h-4 mr-2" />
                <span className="text-md lg:sr-only xl:not-sr-only xl:whitespace-nowrap">
                  Move Funds
                </span>
              </Button>
              <CreateSliceModal
                selectedSlice={slice}
                setSelectedSlice={setSlice}
              />
              <DeleteSliceModal
                selectedSlice={slice}
                setSelectedSlice={setSlice}
                sliceName={slice.name}
              />
            </div>
          </CardHeader>
          <ProgressBar
            value={calculateSlicePercentage(slice)}
            className="w-full"
            color={slice.color}
          />
          <CardContent className="p-6 text-sm">
            <div className="grid gap-3">
              <div className="font-semibold">Slice Details</div>
              <ul className="grid gap-3">
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Type</span>
                  <span>{getEnvelopeTypeText(slice.envelopeType)}</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Status</span>
                  <span>{slice.status ? "Active" : "Inactive"}</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Stashed</span>
                  <span>
                    {"$"}
                    {numberWithCommas((slice.currentAmount / 100).toFixed(2))}
                  </span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Target Amount</span>
                  <span>
                    {"$"}
                    {numberWithCommas((slice.targetAmount / 100).toFixed(2))}
                  </span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">
                    Remaining Amount
                  </span>
                  <span>
                    {"$"}
                    {numberWithCommas(
                      (
                        slice.targetAmount / 100 -
                        slice.currentAmount / 100
                      ).toFixed(2)
                    )}
                  </span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">
                    When you need it by
                  </span>
                  <span>
                    {slice.targetDate} {" ("}
                    {calculateHowManyDaysTillTargetDate(slice.targetDate)} days
                    {")"}
                  </span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">
                    Target Contribution
                  </span>
                  <span>
                    {"$"}
                    {calculateContributionPerDay(
                      slice.currentAmount,
                      slice.targetAmount,
                      slice.targetDate
                    )}{" "}
                    per day
                    {/* TODO: change above to contribution per interval selected for funding */}
                  </span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Funding</span>
                  <span>
                    {"$"}
                    {calculateContributionPerDay(
                      slice.currentAmount,
                      slice.targetAmount,
                      slice.targetDate
                    )}
                    {" / "}
                    {"Payday"}
                    {/* TODO: change above to funding interval selected for slice */}
                  </span>
                </li>
              </ul>
            </div>
          </CardContent>

          <Separator />
          <div className="flex flex-col flex-1">
            <CardContent className="p-6 text-sm">
              <div className="grid gap-3">
                <div className="font-semibold">Rules</div>
              </div>
            </CardContent>
          </div>

          <Separator />
          <div className="flex flex-col flex-1">
            <CardContent className="p-6 text-sm">
              <div className="grid gap-3">
                <div className="font-semibold">Transactions</div>
                <SliceTransactionsTable
                  slice={slice}
                  sliceOptions={sliceOptions || []}
                  categories={categories || []}
                  accounts={accounts || []}
                  categoryOptions={categoryOptions || []}
                  onRowClick={handleRowClick}
                  onEditTransaction={handleEditTransaction}
                  transactions={transactions || []}
                  refetch={refetch}
                  onBulkDelete={handleBulkDelete}
                  onBulkAssign={handleBulkAssign}
                  onBulkUnassign={handleBulkUnassign}
                  onBulkChangeCategory={handleBulkChangeCategory}
                  onBulkChangeDescription={handleBulkChangeDescription}
                  slices={slices || []}
                />
              </div>
            </CardContent>
          </div>
        </Card>
      )}
      {selectedTransaction && (
        <TransactionDetailsView
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
          onEdit={() => setIsEditing(true)}
          isOpen={!!selectedTransaction && !isEditing}
        />
      )}
      {selectedTransaction && (
        <TransactionForm
          transaction={selectedTransaction}
          categories={categories}
          slices={slices}
          onClose={() => {
            setIsEditing(false);
            setSelectedTransaction(null);
            refetch();
          }}
          isOpen={!!selectedTransaction && isEditing}
        />
      )}
    </div>
  );
};

SliceDetails.propTypes = {
  slice: PropTypes.object,
  setSlice: PropTypes.func,
  sliceOptions: PropTypes.array,
  categories: PropTypes.array,
  accounts: PropTypes.array,
  categoryOptions: PropTypes.array,
  slices: PropTypes.array,
};

export default SliceDetails;
