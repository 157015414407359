import React, { useCallback, useEffect, useState, useRef } from "react";
import SafeToSpend from "./SafeToSpend";
import { GET_MOST_RECENT_TRANSACTIONS } from "../queries/transactionQueries";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import RecentTransactionsTable from "./RecentTransactionsTable";
import {
  CHECK_PLAID_SYNC_STATUS,
  GET_ACCOUNTS_FOR_USER,
  GET_USER_PLAID_STATUS,
} from "../queries/plaidQueries";
import {
  COLORS,
  formatCurrency,
  getAccountOptions,
  serializeTransactions,
} from "../lib/utils";
import { Spinner } from "./ui/Spinner";
import PropTypes from "prop-types";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import { TRIGGER_PLAID_SYNC } from "../mutations/plaidMutations";
import toast from "react-hot-toast";
import TransactionDetailsView from "./TransactionDetailsView";
import { TransactionForm } from "./EditTransactionForm";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import {
  BULK_ASSIGN_TRANSACTIONS_TO_SLICE,
  BULK_CHANGE_TRANSACTION_CATEGORY,
  BULK_CHANGE_TRANSACTION_DESCRIPTION,
  BULK_DELETE_TRANSACTIONS,
  BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE,
} from "../mutations/transactionMutations";
import {
  GET_INCOME_VS_EXPENSES,
  GET_SPENDING_BREAKDOWN,
} from "../queries/overviewQueries";
import DateRangeSelector from "./ui/DateRangeSelector";
import SpendingBreakdownChart from "./ui/SpendingBreakdownChart";
import IncomeVsExpensesChart from "./ui/IncomeVsExpensesChart";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";

const Overview = ({ initialDateRange, onDateRangeChange }) => {
  const defaultDateRange = {
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange || defaultDateRange
  );
  const [selectedPreset, setSelectedPreset] = useState("30");
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [syncJobId, setSyncJobId] = useState(null);
  const [syncStatus, setSyncStatus] = useState("idle");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [slices, setSlices] = useState([]);
  const [spendingBreakdown, setSpendingBreakdown] = useState([]);
  const [incomeVsExpenses, setIncomeVsExpenses] = useState([]);

  const [fetchAccounts, { loading: loadingAccounts, error: accountsError }] =
    useLazyQuery(GET_ACCOUNTS_FOR_USER, {
      onCompleted: (data) => {
        setAccounts(data.accountsForUser);
        fetchTransactions();
      },
    });

  const [
    fetchTransactions,
    {
      loading: mostRecentTransactionsForUserLoading,
      error: mostRecentTransactionsForUserError,
    },
  ] = useLazyQuery(GET_MOST_RECENT_TRANSACTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.mostRecentTransactionsForUser) {
        setRecentTransactions(data.mostRecentTransactionsForUser);
      }
    },
  });

  const [
    fetchCategories,
    { loading: loadingCategories, error: categoriesError },
  ] = useLazyQuery(GET_CATEGORIES, {
    onCompleted: (data) => {
      setCategories(data.categories);
    },
  });

  const { data: plaidStatusData, refetch: refetchPlaidStatus } = useQuery(
    GET_USER_PLAID_STATUS
  );

  const { data: syncStatusData, refetch: refetchSyncStatus } = useQuery(
    CHECK_PLAID_SYNC_STATUS,
    {
      variables: { jobId: syncJobId },
      skip: !syncJobId,
      pollInterval: 5000,
    }
  );

  const { loading: loadingSlices, error: slicesError } = useQuery(
    GET_SLICES_FOR_USER,
    {
      onCompleted: (data) => {
        setSlices(data.slicesForUser);
      },
    }
  );

  const [
    fetchSpendingBreakdown,
    { loading: spendingLoading, error: spendingError },
  ] = useLazyQuery(GET_SPENDING_BREAKDOWN, {
    onCompleted: (data) => {
      setSpendingBreakdown(data.spendingBreakdown);
    },
  });

  const [
    fetchIncomeVsExpenses,
    { loading: incomeVsExpensesLoading, error: incomeVsExpensesError },
  ] = useLazyQuery(GET_INCOME_VS_EXPENSES, {
    onCompleted: (data) => {
      setIncomeVsExpenses(data.incomeVsExpenses);
    },
  });

  const handleDateRangeChange = useCallback(
    (newDateRange, newPreset) => {
      if (
        newDateRange.startDate instanceof Date &&
        newDateRange.endDate instanceof Date &&
        (newDateRange.startDate.getTime() !== dateRange.startDate.getTime() ||
          newDateRange.endDate.getTime() !== dateRange.endDate.getTime())
      ) {
        setDateRange(newDateRange);
        setSelectedPreset(newPreset);
        onDateRangeChange(newDateRange);
      }
    },
    [dateRange, onDateRangeChange]
  );

  useEffect(() => {
    if (recentTransactions.length > 0 && accounts.length > 0) {
      const transactions = serializeTransactions(recentTransactions, accounts);
      if (JSON.stringify(transactions) !== JSON.stringify(recentTransactions)) {
        setRecentTransactions(transactions);
      }
    }
  }, [accounts, recentTransactions]);

  useEffect(() => {
    const fetchData = () => {
      if (
        dateRange.startDate instanceof Date &&
        dateRange.endDate instanceof Date
      ) {
        const variables = {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        };

        fetchTransactions({ variables });
        fetchSpendingBreakdown({ variables });
        fetchIncomeVsExpenses({ variables });
      }
    };

    fetchData();
  }, [
    dateRange,
    fetchTransactions,
    fetchSpendingBreakdown,
    fetchIncomeVsExpenses,
  ]);

  useEffect(() => {
    fetchAccounts();
    fetchCategories();
    refetchPlaidStatus();
  }, [fetchAccounts, fetchCategories, refetchPlaidStatus]);

  const [bulkDeleteTransactions] = useMutation(BULK_DELETE_TRANSACTIONS);
  const [bulkAssignTransactionsToSlice] = useMutation(
    BULK_ASSIGN_TRANSACTIONS_TO_SLICE
  );
  const [bulkUnassignTransactionsFromSlice] = useMutation(
    BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE
  );
  const [bulkChangeTransactionCategory] = useMutation(
    BULK_CHANGE_TRANSACTION_CATEGORY
  );
  const [bulkChangeTransactionDescription] = useMutation(
    BULK_CHANGE_TRANSACTION_DESCRIPTION
  );

  const lastSyncedAt = plaidStatusData?.user?.plaidLastSyncedAt
    ? new Date(plaidStatusData.user.plaidLastSyncedAt).toLocaleString()
    : "Never";

  const [triggerPlaidSync, { loading: loadingTriggerPlaidSync }] =
    useMutation(TRIGGER_PLAID_SYNC);

  const [isSyncing, setIsSyncing] = useState(false);

  const handleSyncTransactions = async (force = false) => {
    setIsSyncing(true);
    try {
      const { data } = await triggerPlaidSync({ variables: { force } });
      if (data.triggerPlaidSync.jobId) {
        setSyncJobId(data.triggerPlaidSync.jobId);
        setSyncStatus("in_progress");
        toast.success("Sync started. This may take a few minutes.");
        refetchSyncStatus(); // Add this line to refetch the sync status
      } else if (data.triggerPlaidSync.error) {
        toast.info(data.triggerPlaidSync.error);
      }
    } catch (error) {
      toast.error("Failed to start sync: " + error.message);
    } finally {
      refetchPlaidStatus();
    }
  };

  useEffect(() => {
    if (syncStatusData) {
      setSyncStatus(syncStatusData.plaidSyncStatus);
      if (syncStatusData.plaidSyncStatus === "completed") {
        fetchAccounts();
        fetchCategories();
        refetchPlaidStatus();
        setSyncJobId(null);
        setIsSyncing(false);
        toast.success("Sync completed successfully.");
      } else if (syncStatusData.plaidSyncStatus === "error") {
        toast.error("An error occurred during sync. Please try again.");
        setSyncJobId(null);
        setIsSyncing(false);
      }
    }
  }, [syncStatusData]);

  if (
    mostRecentTransactionsForUserLoading ||
    loadingAccounts ||
    loadingCategories ||
    loadingTriggerPlaidSync ||
    syncStatus === "in_progress" ||
    loadingSlices ||
    spendingLoading ||
    incomeVsExpensesLoading
  ) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center">
          <Spinner />
          {syncStatus === "in_progress" && (
            <p className="mt-4 text-center text-gray-600">
              Syncing accounts and transactions...
            </p>
          )}
        </div>
      </div>
    );
  }

  if (
    mostRecentTransactionsForUserError ||
    accountsError ||
    categoriesError ||
    slicesError ||
    spendingError ||
    incomeVsExpensesError
  ) {
    return <div>Error loading data</div>;
  }

  const handleBulkDelete = async (transactions) => {
    try {
      const result = await bulkDeleteTransactions({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkDeleteTransactions.success) {
        toast.success(`Deleted ${transactions.length} transactions`);
        fetchTransactions();
      } else {
        toast.error(result.data.bulkDeleteTransactions.message);
      }
    } catch (error) {
      toast.error("Failed to delete transactions");
    }
  };

  const handleBulkAssign = async (transactions, sliceId) => {
    try {
      const result = await bulkAssignTransactionsToSlice({
        variables: { ids: transactions.map((t) => t.id), sliceId },
      });
      if (result.data.bulkAssignTransactionsToSlice.success) {
        toast.success(`Assigned ${transactions.length} transactions to slice`);
        fetchTransactions();
      } else {
        toast.error(result.data.bulkAssignTransactionsToSlice.message);
      }
    } catch (error) {
      toast.error("Failed to assign transactions to slice");
    }
  };

  const handleBulkUnassign = async (transactions) => {
    try {
      const result = await bulkUnassignTransactionsFromSlice({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkUnassignTransactionsFromSlice.success) {
        toast.success(
          `Unassigned ${transactions.length} transactions from slice`
        );
        fetchTransactions();
      } else {
        toast.error(result.data.bulkUnassignTransactionsFromSlice.message);
      }
    } catch (error) {
      toast.error("Failed to unassign transactions from slice");
    }
  };

  const handleBulkChangeCategory = async (transactions, categoryId) => {
    try {
      const result = await bulkChangeTransactionCategory({
        variables: { ids: transactions.map((t) => t.id), categoryId },
      });
      if (result.data.bulkChangeTransactionCategory.success) {
        toast.success(
          `Changed category for ${transactions.length} transactions`
        );
        fetchTransactions();
      } else {
        toast.error(result.data.bulkChangeTransactionCategory.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction categories");
    }
  };

  const handleBulkChangeDescription = async (transactions, description) => {
    try {
      const result = await bulkChangeTransactionDescription({
        variables: { ids: transactions.map((t) => t.id), description },
      });
      if (result.data.bulkChangeTransactionDescription.success) {
        toast.success(
          `Changed description for ${transactions.length} transactions`
        );
        fetchTransactions();
      } else {
        toast.error(result.data.bulkChangeTransactionDescription.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction descriptions");
    }
  };

  const daysInRange = Math.ceil(
    (dateRange.endDate.getTime() - dateRange.startDate.getTime()) /
      (1000 * 3600 * 24)
  );

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsEditing(true);
  };

  return (
    <div className="flex-1 flex-col h-full w-full overflow-auto">
      <div className="flex w-full py-3">
        <div className="flex flex-col space-y-4 w-full px-4 pb-4">
          <SafeToSpend
            accounts={accounts}
            lastSyncedAt={lastSyncedAt}
            onSync={() => handleSyncTransactions(true)}
            syncLoading={
              isSyncing ||
              loadingTriggerPlaidSync ||
              syncStatus === "in_progress"
            }
          />
          <DateRangeSelector
            onDateRangeChange={handleDateRangeChange}
            initialDateRange={dateRange}
            selectedPreset={selectedPreset}
            setSelectedPreset={setSelectedPreset}
          />
          <div className="grid grid-cols-1 md:grid-cols-1 mt-4">
            <Card>
              <CardHeader>
                <CardTitle>Spending Breakdown</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col lg:flex-row gap-4">
                  <div className="w-full lg:w-1/2 h-[450px] flex items-center justify-center">
                    {spendingBreakdown.categoryBreakdown &&
                    spendingBreakdown.categoryBreakdown.length > 0 ? (
                      <SpendingBreakdownChart
                        data={spendingBreakdown.categoryBreakdown}
                      />
                    ) : (
                      <div>No spending breakdown data</div>
                    )}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border p-2 text-left">Category</th>
                          <th className="border p-2 text-right">Avg</th>
                          <th className="border p-2 text-right">This period</th>
                        </tr>
                      </thead>
                      <tbody>
                        {spendingBreakdown.categoryBreakdown &&
                        spendingBreakdown.categoryBreakdown.length > 0 ? (
                          spendingBreakdown.categoryBreakdown.map(
                            (category) => (
                              <tr
                                key={category.category.id}
                                className="border-b"
                              >
                                <td className="border p-2 flex items-center">
                                  <span
                                    className="w-3 h-3 mr-2 rounded-full inline-block"
                                    style={{
                                      backgroundColor:
                                        COLORS[
                                          spendingBreakdown.categoryBreakdown.indexOf(
                                            category
                                          )
                                        ],
                                    }}
                                  ></span>
                                  {category.category.name}
                                </td>
                                <td className="border p-2 text-right">
                                  {formatCurrency(
                                    category.amount / daysInRange
                                  )}
                                </td>
                                <td className="border p-2 text-right">
                                  {formatCurrency(category.amount)}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="3" className="border p-2 text-center">
                              No spending breakdown data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="mt-4">
              <CardHeader>
                <CardTitle>Earned vs Spent</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col md:flex-row gap-4 items-center">
                  <div className="w-full md:w-1/3 flex items-center justify-center">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border p-2 text-left">Overview</th>
                          <th className="border p-2 text-right">Avg</th>
                          <th className="border p-2 text-right">This period</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b">
                          <td className="border p-2">Earned</td>
                          <td className="border p-2 text-right text-[#228B22]">
                            {formatCurrency(
                              incomeVsExpenses.income / daysInRange
                            )}
                          </td>
                          <td className="border p-2 text-right text-[#228B22]">
                            {formatCurrency(incomeVsExpenses.income)}
                          </td>
                        </tr>
                        <tr className="border-b">
                          <td className="border p-2">Spent</td>
                          <td className="border p-2 text-right text-[#FF6961]">
                            {formatCurrency(
                              incomeVsExpenses.expenses / daysInRange
                            )}
                          </td>
                          <td className="border p-2 text-right text-[#FF6961]">
                            {formatCurrency(incomeVsExpenses.expenses)}
                          </td>
                        </tr>
                        <tr className="border-b">
                          <td className="border p-2">Sum</td>
                          <td
                            className={`border p-2 text-right ${(incomeVsExpenses.income - incomeVsExpenses.expenses) / daysInRange >= 0 ? "text-[#228B22]" : "text-[#FF6961]"}`}
                          >
                            {formatCurrency(
                              (incomeVsExpenses.income -
                                incomeVsExpenses.expenses) /
                                daysInRange
                            )}
                          </td>
                          <td
                            className={`border p-2 text-right ${incomeVsExpenses.income - incomeVsExpenses.expenses >= 0 ? "text-[#228B22]" : "text-[#FF6961]"}`}
                          >
                            {formatCurrency(
                              incomeVsExpenses.income -
                                incomeVsExpenses.expenses
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full md:w-2/3 flex items-center justify-center">
                    {incomeVsExpenses.dailyBreakdown && (
                      <IncomeVsExpensesChart
                        data={incomeVsExpenses.dailyBreakdown.map((item) => ({
                          ...item,
                          income: item.income,
                          expenses: item.expenses,
                        }))}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          {accounts && categories && (
            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Recent Transactions</CardTitle>
              </CardHeader>
              <CardContent>
                <RecentTransactionsTable
                  transactions={recentTransactions}
                  refetchTransactions={fetchTransactions}
                  setTransactions={setRecentTransactions}
                  accountOptions={getAccountOptions(accounts)}
                  accounts={accounts}
                  categories={categories}
                  onRowClick={handleRowClick}
                  onEditTransaction={handleEditTransaction}
                  onBulkDelete={handleBulkDelete}
                  onBulkAssign={handleBulkAssign}
                  onBulkUnassign={handleBulkUnassign}
                  onBulkChangeCategory={handleBulkChangeCategory}
                  onBulkChangeDescription={handleBulkChangeDescription}
                  slices={slices}
                />
              </CardContent>
            </Card>
          )}
          {selectedTransaction && (
            <TransactionDetailsView
              transaction={selectedTransaction}
              onClose={() => setSelectedTransaction(null)}
              onEdit={() => setIsEditing(true)}
              isOpen={!!selectedTransaction && !isEditing}
            />
          )}
          {selectedTransaction && (
            <TransactionForm
              transaction={selectedTransaction}
              categories={categories}
              slices={slices}
              onClose={() => {
                setIsEditing(false);
                setSelectedTransaction(null);
                fetchTransactions();
              }}
              isOpen={!!selectedTransaction && isEditing}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Overview.propTypes = {
  initialDateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  onDateRangeChange: PropTypes.func,
};

export default Overview;
