import { gql } from "@apollo/client";

const TRANSACTION_FIELDS = gql`
  fragment TransactionFields on Transaction {
    id
    accountId
    transactionId
    amount
    isoCurrencyCode
    category {
      id
      name
      icon
    }
    merchantName
    createdAt
    updatedAt
    transactionLocation
    pending
    pendingTransactionId
    transactionType
    transactionDate
    transactionAuthorizedDate
    envelopeId
    transactionName
    assignedSliceName
    assignedSliceType
    merchantLogoUrl
    accountName
    notes
    status
    isInflow
  }
`;

const GET_TRANSACTIONS_FOR_USER = gql`
  query getTransactionsForUser {
    transactionsForUser {
      ...TransactionFields
    }
  }
  ${TRANSACTION_FIELDS}
`;

const GET_MERCHANTS = gql`
  query getMerchants {
    merchants {
      label
      value
    }
  }
`;

const GET_SAFE_TO_SPEND_BREAKDOWN = gql`
  query getSafeToSpendBreakdown {
    safeToSpendBreakdownForUser {
      availableBalance
      expenseBalance
      protectedBalance
      debtBalance
      goalBalance
      safeToSpend
    }
  }
`;

const GET_MOST_RECENT_TRANSACTIONS = gql`
  query getMostRecentTransactions(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    mostRecentTransactionsForUser(startDate: $startDate, endDate: $endDate) {
      ...TransactionFields
    }
  }
  ${TRANSACTION_FIELDS}
`;

const GET_TRANSACTIONS_FOR_SLICE = gql`
  query getTransactionsForSlice($sliceId: String!) {
    getTransactionsForSlice(sliceId: $sliceId) {
      ...TransactionFields
    }
  }
  ${TRANSACTION_FIELDS}
`;

export {
  GET_TRANSACTIONS_FOR_USER,
  GET_MERCHANTS,
  GET_SAFE_TO_SPEND_BREAKDOWN,
  GET_MOST_RECENT_TRANSACTIONS,
  GET_TRANSACTIONS_FOR_SLICE,
};
