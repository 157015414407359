import React, { useEffect, useState } from "react";
import { Input } from "./ui/Input";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/Popover";
import { Circle } from "react-shapes";
import { CirclePicker } from "react-color";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import CurrencyInput from "react-currency-input-field";
import { Textarea } from "./ui/TextArea";
import { Button } from "./ui/Button";
import { cn } from "../lib/utils";
import PropTypes from "prop-types";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "./ui/Calendar";
import { useMutation } from "@apollo/client";
import { CREATE_SLICE, UPDATE_SLICE } from "../mutations/sliceMutations";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import toast from "react-hot-toast";
import { Switch } from "./ui/Switch";
import { useUser } from "../lib/UserContext";

const formSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Name must be at least 3 characters" })
    .max(50),
  notes: z
    .string()
    .max(250, { message: "Notes can be at most 250 characters" })
    .optional(),
  color: z.string().optional(),
  targetAmount: z
    .number()
    .min(0.01, { message: "Please enter a target amount" }),
  envelopeType: z.string({ required_error: "Please select a slice type." }),
  targetDate: z.date({ required_error: "Please select a target date." }),
  status: z.boolean(),
  currentAmount: z.number(),
});

export const SliceForm = ({
  setOpen,
  setSelectedSlice,
  isEditMode,
  selectedSlice,
}) => {
  const { user } = useUser();
  const [createSlice, { data, loading, error, reset }] = useMutation(
    CREATE_SLICE,
    {
      refetchQueries: [
        {
          query: GET_SLICES_FOR_USER,
        },
      ],
    }
  );
  const [
    updateSlice,
    {
      data: updateSliceData,
      loading: updateSliceLoading,
      error: updateSliceError,
      reset: updateSliceReset,
    },
  ] = useMutation(UPDATE_SLICE, {
    refetchQueries: [
      {
        query: GET_SLICES_FOR_USER,
      },
    ],
  });

  const sliceColors = {
    slate: "#64748b",
    zinc: "#71717a",
    stone: "#78716c",
    red: "#ef4444",
    orange: "#f97316",
    amber: "#f59e0b",
    yellow: "#eab308",
    lime: "#84cc16",
    green: "#22c55e",
    emerald: "#10b981",
    teal: "#14b8a6",
    cyan: "#06b6d4",
    sky: "#0ea5e9",
    blue: "#3b82f6",
    indigo: "#6366f1",
    violet: "#8b5cf6",
    purple: "#a855f7",
    fuchsia: "#d946ef",
    rose: "#f43f5e",
    pink: "#ec4899",
  };
  const [color, setColor] = useState(
    !isEditMode ? "#ec4899" : sliceColors[selectedSlice?.color]
  );

  const getDefaultValues = () => {
    if (isEditMode) {
      return {
        name: selectedSlice.name,
        envelopeType: selectedSlice.envelopeType.toUpperCase(),
        targetAmount: selectedSlice.targetAmount / 100,
        targetDate: new Date(
          new Date(selectedSlice.targetDate).setDate(
            new Date(selectedSlice.targetDate).getDate() + 1
          )
        ),
        currentAmount: selectedSlice.currentAmount / 100,
        notes: selectedSlice.notes,
        color: selectedSlice.color,
        status: selectedSlice.status,
      };
    } else {
      return {
        name: "",
        envelopeType: "",
        targetAmount: 0,
        targetDate: null,
        currentAmount: 0,
        notes: "",
        color,
        status: true,
      };
    }
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });

  const handleColorChange = (color) => {
    // Find the key in sliceColors that matches the selected color
    const colorKey = Object.keys(sliceColors).find(
      (key) => sliceColors[key] === color.hex
    );

    if (colorKey && form) {
      form.setValue("color", colorKey);
      setColor(color.hex);
    }
  };

  function onSubmit(values) {
    // convert target_date to "YYYY-MM-DD"
    let targetDate = values.targetDate;
    if (targetDate) {
      targetDate = targetDate.toISOString().split("T")[0];
    }

    let finalValues = {
      ...values,
      envelopeType: values.envelopeType.toUpperCase(),
      targetAmount: values.targetAmount * 100,
      currentAmount: values.currentAmount * 100,
      targetDate,
      userId: user.id,
      status: isEditMode ? values.status : true,
    };

    if (color === "#ec4899") {
      finalValues = { ...finalValues, color: "pink" };
    }

    if (isEditMode) {
      updateSlice({
        variables: {
          id: selectedSlice.id,
          ...finalValues,
        },
      });
    } else {
      createSlice({ variables: finalValues });
    }
  }

  useEffect(() => {
    if (data && data.createSlice.envelope) {
      toast.success("Slice created!", { duration: 5000 });
      setSelectedSlice(data.createSlice.envelope);
      setOpen(false);
    }

    if (updateSliceData && updateSliceData.updateSlice.envelope) {
      toast.success("Slice updated!", { duration: 5000 });
      setSelectedSlice(updateSliceData.updateSlice.envelope);
      setOpen(false);
    }

    if (
      error ||
      (data && data.createSlice.errors.length > 0) ||
      updateSliceError ||
      (updateSliceData && updateSliceData.updateSlice.errors.length > 0)
    ) {
      toast.error("Uh oh! Something went wrong. Please try again.");
    }

    if (isEditMode) {
      form.setValue("name", selectedSlice.name);
      form.setValue("envelopeType", selectedSlice.envelopeType);
      form.setValue("targetAmount", selectedSlice.targetAmount / 100);
      form.setValue(
        "targetDate",
        new Date(
          new Date(selectedSlice.targetDate).setDate(
            new Date(selectedSlice.targetDate).getDate() + 1
          )
        )
      );
      form.setValue("notes", selectedSlice.notes);
      form.setValue("color", selectedSlice.color);
      form.setValue("status", selectedSlice.status);
    }
  }, [data, error, updateSliceData, updateSliceError]);

  return (
    <Form {...form}>
      <form
        className={cn("grid items-start gap-4")}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="grid gap-2">
          <div className="flex flex-row items-center justify-between gap-x-6 w-full">
            <FormField
              control={form.control}
              name="name"
              className="grid gap-2"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="font-semibold">Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Rent" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="color"
              className="grid gap-2"
              render={({ field }) => (
                <FormItem className="w-auto flex flex-col ml-auto items-center">
                  <FormLabel className="font-semibold">Color</FormLabel>
                  <FormControl>
                    <Popover>
                      <PopoverTrigger>
                        <Circle r={11} fill={{ color }} />
                      </PopoverTrigger>
                      <PopoverContent>
                        <CirclePicker
                          colors={Object.values(sliceColors)}
                          onChangeComplete={(color) => handleColorChange(color)}
                          circleSize={19}
                          {...field}
                        />
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="envelopeType"
            className="w-full"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold">Slice Type</FormLabel>

                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a slice type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="EXPENSE_TYPE">Expense</SelectItem>
                    <SelectItem value="GOAL_TYPE">Goal</SelectItem>
                    <SelectItem value="DEBT_TYPE">Debt</SelectItem>
                    <SelectItem value="PROTECTED_TYPE">Protected</SelectItem>
                  </SelectContent>
                  <FormMessage />
                </Select>
              </FormItem>
            )}
          />
        </div>
        <div className="grid gap-2">
          <div className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="targetAmount"
              className="grid gap-2 w-full"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">Target Amount</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      onValueChange={(value) => {
                        const numericValue = value ? parseFloat(value) : 0;
                        form.setValue(
                          "targetAmount",
                          isNaN(numericValue) ? 0 : numericValue
                        );
                      }}
                      placeholder="$0.00"
                      decimalsLimit={2}
                      allowNegativeValue={false}
                      className="flex h-9 w-full rounded-md border border-gray-400 bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus:ring-pink-vivid-500 disabled:cursor-not-allowed disabled:opacity-50"
                      prefix="$"
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="currentAmount"
              className="grid gap-2 w-full"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">
                    Current Amount
                  </FormLabel>
                  <FormControl>
                    <CurrencyInput
                      onValueChange={(value) => {
                        const numericValue = value ? parseFloat(value) : 0;
                        form.setValue(
                          "currentAmount",
                          isNaN(numericValue) ? 0 : numericValue
                        );
                      }}
                      placeholder="$0.00"
                      decimalsLimit={2}
                      allowNegativeValue={false}
                      className="flex h-9 w-full rounded-md border border-gray-400 bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus:ring-pink-vivid-500 disabled:cursor-not-allowed disabled:opacity-50"
                      prefix="$"
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="targetDate"
            rules={{ required: "Please select a target date." }}
            className="grid gap-2 w-full"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="font-semibold">
                  When do you need it by?
                </FormLabel>
                <Popover>
                  <PopoverTrigger>
                    <Button
                      variant={"outline"}
                      type="button"
                      className={cn(
                        "w-full pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value ? (
                        format(field.value, "PPP")
                      ) : (
                        <span>Pick a date</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-full p-0" align="start">
                    <FormControl>
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={(selectedDate) => {
                          field.onChange(selectedDate);
                        }}
                        disabled={(date) => date < new Date()}
                        defaultDate={
                          isEditMode
                            ? new Date(
                                new Date(selectedSlice.targetDate).setDate(
                                  new Date(selectedSlice.targetDate).getDate() +
                                    1
                                )
                              )
                            : new Date()
                        }
                      />
                    </FormControl>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {isEditMode ? (
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="status"
              className="grid gap-2"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <div className="space-y-0.5">
                    <FormLabel className="font-semibold">Status</FormLabel>
                    <FormDescription className="text-gray-700">
                      {field.value ? "Active" : "Paused"}
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="notes"
            className="grid gap-2 w-full"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold">Notes</FormLabel>
                <FormControl>
                  <Textarea
                    rows={1}
                    className="w-full"
                    placeholder="notes, tags, and 😌"
                    required={false}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-between w-full gap-x-6">
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              setOpen(false);
              if (isEditMode) {
                updateSliceReset();
              } else {
                reset();
              }
            }}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            {loading
              ? updateSliceLoading
                ? "Updating..."
                : "Creating..."
              : isEditMode
                ? "Update Slice"
                : "Create Slice"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

SliceForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  setSelectedSlice: PropTypes.func,
  isEditMode: PropTypes.bool,
  selectedSlice: PropTypes.object,
};
