import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { defaultNavItems } from "./defaultNavItems";
import { NavigationLink } from "./NavigationLink";
import PropTypes from "prop-types";
import { Separator } from "../ui/Separator";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { formatCurrency } from "../../lib/utils";
import { Spinner } from "../ui/Spinner";
import { useSafeToSpend } from "../SafeToSpend";
import { Menu, X, LogOut } from "lucide-react"; // Import LogOut icon
import { Button } from "../ui/Button";
import { useUser } from "../../lib/UserContext";
import { useLazyQuery } from "@apollo/client";
import { USER_SIGN_OUT } from "../../queries/userQueries";
import toast from "react-hot-toast";
import { Mail } from "lucide-react"; // Add this import at the top of the file
import LogoutModal from "../LogoutModal"; // Add this import at the top of the file

const MenuContent = ({ setCurrentNavItem }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { breakdownData, breakdownLoading } = useSafeToSpend();
  const [signOut, { loading: signOutLoading }] = useLazyQuery(USER_SIGN_OUT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      userContext.signOut();
      navigate("/login");
    },
    onError: (error) => {
      console.error("Logout error", error);
      toast.error("Error signing out");
    },
  });

  const safeToSpendBreakdown = breakdownData?.safeToSpendBreakdownForUser || {
    availableBalance: 0,
    expenseBalance: 0,
    protectedBalance: 0,
    debtBalance: 0,
    goalBalance: 0,
    safeToSpend: 0,
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    signOut();
  };

  if (breakdownLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex w-full flex-col text-sm">
      <div className="flex items-center justify-between lg:hidden">
        <Link
          to="/"
          onClick={() => setCurrentNavItem("overview")}
          className="py-4"
        >
          <span className="text-lg font-semibold tracking-tight">
            Cake Budget
          </span>
        </Link>
        <button onClick={toggleMenu} className="p-2">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      <div
        className={`flex flex-col ${isMenuOpen ? "block" : "hidden"} lg:block`}
      >
        <Link
          to="/"
          onClick={() => setCurrentNavItem("overview")}
          className="hidden lg:inline-flex items-center"
        >
          <div className="flex flex-row gap-x-2 py-4 w-full">
            <span className="text-lg font-semibold tracking-tight">
              Cake Budget
            </span>
          </div>
        </Link>
        {location.pathname !== "/" && (
          <div className="flex flex-col my-2 gap-x-2">
            <Separator orientation="horizontal" />
            <Popover>
              <PopoverTrigger className="flex flex-col py-2">
                <span className="font-bold text-lg">Safe To Spend</span>
                <span className="font-medium text-sm">
                  {formatCurrency(safeToSpendBreakdown.safeToSpend)}
                </span>
              </PopoverTrigger>
              <PopoverContent className="w-full p-4" align="end">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left font-bold text-lg" colSpan={2}>
                        Available Balance:{" "}
                        {formatCurrency(safeToSpendBreakdown.availableBalance)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left font-medium text-sm">
                        Expenses:
                      </td>
                      <td className="text-right font-medium text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown?.envelopeBalances
                            ?.expense_type || 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-medium text-sm">Goals:</td>
                      <td className="text-right font-medium text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown.envelopeBalances.goal_type || 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-medium text-sm">
                        Protected:
                      </td>
                      <td className="text-right font-medium text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown.envelopeBalances
                            .protected_type || 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-medium text-sm">Debt:</td>
                      <td className="text-right font-medium text-sm">
                        {formatCurrency(
                          safeToSpendBreakdown.envelopeBalances.debt_type || 0
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </PopoverContent>
            </Popover>
          </div>
        )}
        <Separator orientation="horizontal" />
        <nav className="flex flex-col mt-4 mb-4">
          <ul role="list" className="flex flex-1 flex-col">
            <li>
              <ul role="list" className="-mx-2 space-y-2">
                {defaultNavItems.map((navItem, index) => (
                  <li key={index}>
                    <NavigationLink
                      href={navItem.href}
                      label={navItem.label}
                      icon={navItem.icon}
                      onClick={() => {
                        setCurrentNavItem(navItem.id);
                        setIsMenuOpen(false); // Close menu on mobile after selection
                      }}
                    />
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
        <div className="mt-auto lg:hidden">
          <Button
            variant="ghost"
            className="rounded-md text-center w-full p-2 hover:bg-gray-100 hover:text-gray-900"
            onClick={() =>
              (window.location.href =
                "mailto:contact@cakebudget.io?subject=Cake Budget Support: [YOUR REQUEST HERE]")
            }
          >
            <Mail className="mr-2 h-4 w-4" />
            Email Support
          </Button>
          <LogoutModal />
        </div>
      </div>
      <hr className="mt-auto" />
    </div>
  );
};

MenuContent.propTypes = {
  setCurrentNavItem: PropTypes.func.isRequired,
  safeToSpendData: PropTypes.object,
  safeToSpendLoading: PropTypes.bool,
  refetchSafeToSpend: PropTypes.func,
};

export default MenuContent;
