import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  CHECK_PLAID_SYNC_STATUS,
  GET_ACCOUNTS_FOR_USER,
  GET_USER_PLAID_STATUS,
} from "../queries/plaidQueries";
import {
  SYNC_PLAID_TRANSACTIONS,
  TRIGGER_PLAID_SYNC,
} from "../mutations/plaidMutations";
import { Button } from "./ui/Button";
import PropTypes from "prop-types";
import DataTable from "./data-table";
import { transactionColumns } from "./data-table/transactionColumns";
import { Separator } from "./ui/Separator";
import { GET_TRANSACTIONS_FOR_USER } from "../queries/transactionQueries";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import {
  getAccountOptions,
  getCategoryOptions,
  getSliceOptions,
  serializeTransactions,
} from "../lib/utils";
import { Spinner } from "./ui/Spinner";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import toast from "react-hot-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/Dialog";
import TransactionDetailsView from "./TransactionDetailsView";
import { TransactionForm } from "./EditTransactionForm";
import BulkActionMenu from "./BulkActionMenu";
import {
  BULK_ASSIGN_TRANSACTIONS_TO_SLICE,
  BULK_CHANGE_TRANSACTION_CATEGORY,
  BULK_CHANGE_TRANSACTION_DESCRIPTION,
  BULK_DELETE_TRANSACTIONS,
  BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE,
} from "../mutations/transactionMutations";

const TransactionsHome = () => {
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [slices, setSlices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [syncJobId, setSyncJobId] = useState(null);
  const [syncStatus, setSyncStatus] = useState("idle");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { refetch: refetchUserPlaidStatus } = useQuery(GET_USER_PLAID_STATUS);

  const {
    loading: loadingAccounts,
    error: accountsError,
    refetch: refetchAccounts,
  } = useQuery(GET_ACCOUNTS_FOR_USER, {
    onCompleted: (data) => {
      setAccounts(data.accountsForUser);
      refetchTransactions();
    },
  });

  const {
    loading: loadingTransactions,
    error: transactionsError,
    refetch: refetchTransactions,
  } = useQuery(GET_TRANSACTIONS_FOR_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.transactionsForUser) {
        const transactions = serializeTransactions(
          data.transactionsForUser,
          accounts
        );
        setTransactions(transactions);
      } else {
        console.error("No transaction data received");
        setTransactions([]);
      }
    },
    onError: (error) => {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
    },
  });

  const [, { loading: loadingSyncTransactions }] = useMutation(
    SYNC_PLAID_TRANSACTIONS,
    {
      onCompleted: (data) => {
        const transactions = serializeTransactions(
          data.syncPlaidTransactions.transactions,
          accounts
        );
        setTransactions(transactions);
      },
    }
  );

  const {
    loading: loadingSlices,
    error: slicesError,
    refetch: refetchSlices,
  } = useQuery(GET_SLICES_FOR_USER, {
    onCompleted: (data) => {
      setSlices(data.slicesForUser);
    },
  });

  const {
    loading: loadingCategories,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_CATEGORIES);

  const [triggerPlaidSync, { loading: loadingTriggerPlaidSync }] =
    useMutation(TRIGGER_PLAID_SYNC);

  const { data: syncStatusData } = useQuery(CHECK_PLAID_SYNC_STATUS, {
    variables: { jobId: syncJobId },
    skip: !syncJobId,
    pollInterval: 5000, // Poll every 5 seconds
  });

  const [bulkDeleteTransactions] = useMutation(BULK_DELETE_TRANSACTIONS);
  const [bulkAssignTransactionsToSlice] = useMutation(
    BULK_ASSIGN_TRANSACTIONS_TO_SLICE
  );
  const [bulkUnassignTransactionsFromSlice] = useMutation(
    BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE
  );
  const [bulkChangeTransactionCategory] = useMutation(
    BULK_CHANGE_TRANSACTION_CATEGORY
  );
  const [bulkChangeTransactionDescription] = useMutation(
    BULK_CHANGE_TRANSACTION_DESCRIPTION
  );

  useEffect(() => {
    if (syncStatusData) {
      setSyncStatus(syncStatusData.plaidSyncStatus);
      if (syncStatusData.plaidSyncStatus === "completed") {
        handleRefreshTransactions();
        refetchAccounts();
        refetchSlices();
        setSyncJobId(null);
        toast.success("Sync completed successfully.");
      } else if (syncStatusData.plaidSyncStatus === "error") {
        toast.error("An error occurred during sync. Please try again.");
        setSyncJobId(null);
      }
    }
  }, [syncStatusData]);

  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData.categories);
    }
  }, [categoriesData]);

  useEffect(() => {
    refetchAccounts();
    refetchSlices();
  }, [refetchAccounts, refetchSlices]);

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsEditing(true);
  };

  const handleSyncTransactions = async (force = false) => {
    try {
      const { data } = await triggerPlaidSync({ variables: { force } });
      if (data.triggerPlaidSync.jobId) {
        setSyncJobId(data.triggerPlaidSync.jobId);
        setSyncStatus("in_progress");
        toast.success("Sync started. This may take a few minutes.");
      } else if (data.triggerPlaidSync.error) {
        toast.info(data.triggerPlaidSync.error);
      }
    } catch (error) {
      toast.error("Failed to start sync: " + error.message);
    } finally {
      refetchUserPlaidStatus();
    }
  };

  const handleRefreshTransactions = async () => {
    try {
      const { data } = await refetchTransactions();
      const updatedTransactions = serializeTransactions(
        data.transactionsForUser,
        accounts
      );
      setTransactions(updatedTransactions);
    } catch (error) {
      console.error("Error refreshing transactions:", error);
      toast.error("Failed to refresh transactions. Please try again.");
    }
  };

  const handleBulkDelete = async (transactions) => {
    try {
      const result = await bulkDeleteTransactions({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkDeleteTransactions.success) {
        toast.success(`Deleted ${transactions.length} transactions`);
        handleRefreshTransactions();
      } else {
        toast.error(result.data.bulkDeleteTransactions.message);
      }
    } catch (error) {
      toast.error("Failed to delete transactions");
    }
  };

  const handleBulkAssign = async (transactions, sliceId) => {
    try {
      const result = await bulkAssignTransactionsToSlice({
        variables: { ids: transactions.map((t) => t.id), sliceId },
      });
      if (result.data.bulkAssignTransactionsToSlice.success) {
        toast.success(`Assigned ${transactions.length} transactions to slice`);
        handleRefreshTransactions();
      } else {
        toast.error(result.data.bulkAssignTransactionsToSlice.message);
      }
    } catch (error) {
      toast.error("Failed to assign transactions to slice");
    }
  };

  const handleBulkUnassign = async (transactions) => {
    try {
      const result = await bulkUnassignTransactionsFromSlice({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkUnassignTransactionsFromSlice.success) {
        toast.success(
          `Unassigned ${transactions.length} transactions from slice`
        );
        handleRefreshTransactions();
      } else {
        toast.error(result.data.bulkUnassignTransactionsFromSlice.message);
      }
    } catch (error) {
      toast.error("Failed to unassign transactions from slice");
    }
  };

  const handleBulkChangeCategory = async (transactions, categoryId) => {
    try {
      const result = await bulkChangeTransactionCategory({
        variables: { ids: transactions.map((t) => t.id), categoryId },
      });
      if (result.data.bulkChangeTransactionCategory.success) {
        toast.success(
          `Changed category for ${transactions.length} transactions`
        );
        handleRefreshTransactions();
      } else {
        toast.error(result.data.bulkChangeTransactionCategory.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction categories");
    }
  };

  const handleBulkChangeDescription = async (transactions, description) => {
    try {
      const result = await bulkChangeTransactionDescription({
        variables: { ids: transactions.map((t) => t.id), description },
      });
      if (result.data.bulkChangeTransactionDescription.success) {
        toast.success(
          `Changed description for ${transactions.length} transactions`
        );
        handleRefreshTransactions();
      } else {
        toast.error(result.data.bulkChangeTransactionDescription.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction descriptions");
    }
  };

  if (
    loadingAccounts ||
    loadingTransactions ||
    loadingSyncTransactions ||
    loadingSlices ||
    loadingCategories ||
    loadingTriggerPlaidSync ||
    syncStatus === "in_progress"
  ) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center">
          <Spinner />
          {syncStatus === "in_progress" && (
            <p className="mt-4 text-center text-gray-600">
              Syncing accounts and transactions...
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Transactions</h2>
              <div className="flex flex-col items-center">
                <div className="flex space-x-2">
                  <Button
                    onClick={() => handleSyncTransactions(true)}
                    disabled={
                      loadingSyncTransactions || syncStatus === "in_progress"
                    }
                    variant="outline"
                  >
                    {syncStatus === "in_progress"
                      ? "Syncing..."
                      : "Sync Transactions"}
                  </Button>
                </div>
                <div className="mt-2 text-center">
                  {syncStatus === "in_progress" && <p>Sync in progress...</p>}
                  {syncStatus === "error" && (
                    <p className="text-red-500">Error occurred during sync</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Separator />
          {accountsError && (
            <div>Error fetching accounts: {accountsError.message}</div>
          )}
          {transactionsError && (
            <div>Error fetching transactions: {transactionsError.message}</div>
          )}
          {slicesError && (
            <div>Error fetching slices: {slicesError.message}</div>
          )}
          {categoriesError && (
            <div>Error fetching categories: {categoriesError.message}</div>
          )}
          {transactions.length > 0 ? (
            <div className="p-4">
              <DataTable
                data={transactions}
                accounts={accounts}
                columns={transactionColumns}
                accountOptions={getAccountOptions(accounts)}
                sliceOptions={getSliceOptions(slices)}
                refreshTransactions={handleRefreshTransactions}
                categories={categories}
                categoryOptions={getCategoryOptions(categories)}
                onRowClick={handleRowClick}
                onEditTransaction={handleEditTransaction}
                selectedRows={transactions}
                onBulkDelete={handleBulkDelete}
                onBulkAssign={handleBulkAssign}
                onBulkUnassign={handleBulkUnassign}
                onBulkChangeCategory={handleBulkChangeCategory}
                onBulkChangeDescription={handleBulkChangeDescription}
                slices={slices}
              />
              {selectedTransaction && (
                <TransactionDetailsView
                  transaction={selectedTransaction}
                  onClose={() => setSelectedTransaction(null)}
                  onEdit={() => setIsEditing(true)}
                  isOpen={!!selectedTransaction && !isEditing}
                />
              )}
              {selectedTransaction && (
                <TransactionForm
                  transaction={selectedTransaction}
                  categories={categories}
                  slices={slices}
                  onClose={() => {
                    setIsEditing(false);
                    setSelectedTransaction(null);
                    handleRefreshTransactions();
                  }}
                  isOpen={!!selectedTransaction && isEditing}
                />
              )}
            </div>
          ) : (
            <div className="p-4 text-center text-gray-500">
              No transactions available. Sync your accounts to see transactions
              here.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TransactionsHome.propTypes = {
  setNeedPlaidReconnection: PropTypes.func,
};

export default TransactionsHome;
