import React from "react";
import Layout from "./layout/Layout";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {

  return <Layout />;
};

export default App;
