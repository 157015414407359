import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { cn } from "../../lib/utils";

export const NavigationLink = memo(({ href, label, icon: Icon, onClick }) => {
  const location = useLocation();
  const iconComponent = Icon ? <Icon className={"h-6 w-6 shrink-0"} /> : null;
  let isActive = false;

  if (location.pathname.length > 0) {
    const splittedPathname = location.pathname.split("/");
    const currentPathname = splittedPathname[1] ?? "";
    isActive = currentPathname === href.split("/")[1];
  }

  return (
    <NavLink
      to={href}
      className={cn(
        "group flex items-center justify-between rounded-lg p-2",
        isActive ? "bg-black text-white" : "hover:bg-gray-200"
      )}
      onClick={onClick}
    >
      <span className="flex items-center gap-2">
        {iconComponent}
        <span className={cn("font-medium", isActive && "text-white")}>
          {label}
        </span>
      </span>
    </NavLink>
  );
});

NavigationLink.displayName = "NavigationLink";

NavigationLink.propTypes = {
  key: PropTypes.string,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  shortcutNumber: PropTypes.number,
  onClick: PropTypes.func,
};
