import { useMutation } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { z } from "zod";
import { USER_SIGN_UP } from "../mutations/userMutations";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "./ui/Input";
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { passwordValidation } from "../lib/utils";

const signUpSchema = z
  .object({
    email: z.string().email({ message: "Invalid email address" }).min(1),
    password: z
      .string()
      .min(8, { message: "Minimum 8 characters required" })
      .regex(passwordValidation, {
        message:
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      }),
    passwordConfirmation: z
      .string()
      .min(8, { message: "Password confirmation required" }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ["passwordConfirmation"],
    message: "Password and confirmation do not match",
  });

const UserSignUp = () => {
  const [signUpUser, { data, error, loading }] = useMutation(USER_SIGN_UP);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  useEffect(() => {
    if (data?.userSignUp?.user) {
      toast.success(
        "You're signed up! Please check your email to confirm your account."
      );
    }

    if (error) {
      toast.error(error.message);
    }
  }, [data, error]);

  const onSubmit = (values) => {
    signUpUser({ variables: values });
  };

  if (data?.userSignUp?.user) {
    return (
      <div className="flex flex-col h-screen justify-center px-6 py-12 lg:px-8 items-center align-middle space-y-4">
        <Toaster />
        <h1 className="text-center">{"You're signed up!"}</h1>
        <span className="text-center">
          We&apos;re so glad you&apos;re ready to join Cake Budget! <br />
          Please check your email to confirm your account so you can get
          started.
        </span>
      </div>
    );
  }

  return (
    <div className="flex h-full bg-white flex-col justify-center px-6 py-12 lg:px-8">
      <div>
        <Toaster />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign up
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </Label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="you@example.com"
                required
                className={classNames(
                  "block w-full rounded-md py-1.5",
                  errors.email
                    ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    : "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset",
                  "sm:text-sm sm:leading-6"
                )}
                aria-invalid={errors.email ? "true" : "false"}
                aria-describedby="email-error"
                {...register("email")}
              />
              {errors?.email ? (
                <p
                  className="mt-2 text-sm text-red-600"
                  id="password-confirmation-error"
                >
                  {errors.email.message}
                </p>
              ) : null}
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password (8 characters minimum)
              </Label>
            </div>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Input
                id="password"
                name="password"
                type="password"
                required
                minLength={6}
                className={classNames(
                  "block w-full rounded-md py-1.5",
                  errors.password
                    ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    : "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset",
                  "sm:text-sm sm:leading-6"
                )}
                aria-invalid={errors?.password ? "true" : "false"}
                aria-describedby="password-error"
                {...register("password")}
              />

              {errors?.password || errors?.passwordConfirmation ? (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  {errors?.password?.message ||
                    errors?.passwordConfirmation?.message}
                </p>
              ) : null}
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="passwordConfirmation"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password Confirmation
              </Label>
            </div>
            <div className="relative mt-2 rounded-md shadow-sm">
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                minLength={6}
                required
                className={classNames(
                  "block w-full rounded-md py-1.5",
                  errors.passwordConfirmation
                    ? "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
                    : "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset",
                  "sm:text-sm sm:leading-6"
                )}
                aria-invalid={errors?.passwordConfirmation ? "true" : "false"}
                aria-describedby="password-confirmation-error"
                {...register("passwordConfirmation")}
              />

              {errors?.passwordConfirmation ? (
                <p
                  className="mt-2 text-sm text-red-600"
                  id="password-confirmation-error"
                >
                  {errors?.passwordConfirmation?.message}
                </p>
              ) : null}
            </div>
          </div>

          <div>
            <Button
              type="submit"
              className="flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              disabled={loading}
              variant="default"
            >
              {loading ? "Signing up..." : "Sign up"}
            </Button>
          </div>
        </form>
        <p className="mt-10 text-center text-sm text-gray-500">
          Have an account?
          <br />
          <a href="/login" className="font-semibold leading-6 text-black">
            Sign In
          </a>
        </p>
      </div>
    </div>
  );
};

export default UserSignUp;
