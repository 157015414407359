import React, { useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "./ui/Button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/AlertDialog";
import { useLazyQuery } from "@apollo/client";
import { useUser } from "../lib/UserContext";
import { useNavigate } from "react-router";
import { USER_SIGN_OUT } from "../queries/userQueries";
import toast from "react-hot-toast";
import { LogOut } from "lucide-react"; // Import LogOut icon

const LogoutModal = () => {
  const userContext = useUser();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [signOut, { loading }] = useLazyQuery(USER_SIGN_OUT, {
    fetchPolicy: "network-only", // Ensures the query is sent to the server
    onCompleted: () => {
      setOpen(false);
      userContext.signOut();
      navigate("/login");
    },
    onError: (error) => {
      // Handle logout error
      console.error("Logout error", error);
      toast.error("Error signing out");
    },
  });

  const handleLogout = async () => {
    signOut();
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="ghost"
          onClick={() => setOpen(true)}
          className="rounded-md text-center w-full p-2 hover:bg-gray-100 hover:text-gray-900"
        >
          <LogOut className="mr-2 h-4 w-4" />
          Sign Out
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent className="bg-white sm:max-w-[425px]o">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex flex-row items-center gap-2">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" /> Sign
            Out
          </AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to sign out?
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleLogout} disabled={loading}>
              Sign Out
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LogoutModal;
