import { gql } from "@apollo/client";

const CREATE_SLICE = gql`
  mutation createSliceForUser(
    $name: String!
    $envelopeType: EnvelopeTypeEnum!
    $targetAmount: Float!
    $currentAmount: Float
    $notes: String
    $targetDate: ISO8601Date!
    $color: String
    $status: Boolean!
  ) {
    createSlice(
      input: {
        name: $name
        envelopeType: $envelopeType
        targetAmount: $targetAmount
        currentAmount: $currentAmount
        notes: $notes
        targetDate: $targetDate
        color: $color
        status: $status
      }
    ) {
      envelope {
        id
        name
        envelopeType
        targetAmount
        currentAmount
        notes
        color
        status
        targetDate
        rules {
          id
          name
          ruleCategory
          active
          ruleConditions {
            id
            field
            operator
            value
          }
          ruleActions {
            id
            actionType
            parameters
          }
          ruleSchedule {
            id
            interval
            nextRun
          }
        }
      }
      errors
    }
  }
`;

const DELETE_SLICE = gql`
  mutation deleteSliceForUser($id: ID!) {
    deleteSlice(input: { id: $id }) {
      success
      errors
    }
  }
`;

const UPDATE_SLICE = gql`
  mutation updateSliceForUser(
    $id: ID!
    $name: String!
    $envelopeType: EnvelopeTypeEnum!
    $targetAmount: Float!
    $currentAmount: Float
    $notes: String
    $targetDate: ISO8601Date!
    $color: String
    $status: Boolean!
  ) {
    updateSlice(
      input: {
        id: $id
        name: $name
        envelopeType: $envelopeType
        targetAmount: $targetAmount
        currentAmount: $currentAmount
        notes: $notes
        targetDate: $targetDate
        color: $color
        status: $status
      }
    ) {
      envelope {
        id
        name
        envelopeType
        targetAmount
        currentAmount
        notes
        color
        status
        targetDate
        rules {
          id
          name
          ruleCategory
          active
          ruleConditions {
            id
            field
            operator
            value
          }
          ruleActions {
            id
            actionType
            parameters
          }
          ruleSchedule {
            id
            interval
            nextRun
          }
        }
      }
      errors
    }
  }
`;

export { CREATE_SLICE, DELETE_SLICE, UPDATE_SLICE };
