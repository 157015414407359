import { clsx, type ClassValue } from "clsx"
import { useEffect } from "react"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function useKeyPress(callback, keyCodes) {
  useEffect(() => {
    const handler = (event) => {
      if (keyCodes.includes(event.code)) {
        callback(event);
      }
    }

    window.addEventListener("keydown", handler)
    return () => {
      window.removeEventListener("keydown", handler)
    }
  }, [callback, keyCodes])
}

export const getEnvelopeTypeColor = (type) => {
  switch (type) {
    case "expense_type":
      return "bg-red-500";
    case "goal_type":
      return "bg-emerald-500";
    case "protected_type":
      return "bg-amber-500";
    case "debt_type":
      return "bg-debt";
  }
};

export const getEnvelopeTypeText = (type) => {
  switch (type) {
    case "expense_type":
      return "Expense";
    case "goal_type":
      return "Goal";
    case "protected_type":
      return "Protected";
    case "debt_type":
      return "Debt";
  }
};

export const getRuleTypeText = (type) => {
  switch (type) {
    case "transaction_rule":
      return "Transaction Rule";
    case "funding_rule":
      return "Funding Rule";
    case "balance_rule":
      return "Balance Rule";
    case "date_rule":
      return "Date-based Rule";
  }
};

export const getRuleIntervalText = (interval) => {
  switch (interval) {
    case "daily":
      return "Daily";
    case "weekly":
      return "Weekly";
    case "biweekly":
      return "Biweekly";
    case "monthly":
      return "Monthly";
    case "bimonthly":
      return "Bimonthly";
    case "quarterly":
      return "Quarterly";
    case "semiannually":
      return "Semiannually";
    case "annually":
      return "Annually";
    case "on_deposit":
      return "On Deposit";
    case "on_balance_threshold":
      return "On Balance Threshold";
  }
};
export const calculateSlicePercentage = (slice) => {
  const currentAmount = slice.currentAmount / 100;
  const targetAmount = slice.targetAmount / 100;
  const percentage = (currentAmount / targetAmount) * 100;
  return percentage || 0;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateRemainingBalance = (currentAmount, targetAmount) => {
  return targetAmount - currentAmount;
};

export const calculateContributionPerDay = (
  currentAmount,
  targetAmount,
  targetDate
) => {
  const remainingBalance = calculateRemainingBalance(
    currentAmount,
    targetAmount
  );
  const daysUntilTargetDate = calculateHowManyDaysTillTargetDate(targetDate);
  return (remainingBalance / 100 / daysUntilTargetDate).toFixed(2);
};

export const calculateHowManyDaysTillTargetDate = (targetDate) => {
  const target = new Date(targetDate);
  const currentDate = new Date();
  return Math.round(
    (target.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
  );
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount / 100);
};

export const getTransactionAmount = (transaction) => {
  return transaction.isInflow ? transaction.amount : -transaction.amount;
};

export const formatTransactionAmount = (transaction) => {
  const amount = getTransactionAmount(transaction);
  return formatCurrency(amount);
};

export const serializeTransactions = (transactions, accounts) => {
  if (!transactions) return [];

  return transactions.map((transaction) => {
    return {
      ...transaction,
      amount: transaction.amount ?? 0,
      transactionName: transaction.transactionName ?? '',
      category: transaction.category ?? { name: 'Uncategorized' },
      categoryName: transaction.category?.name ?? 'Uncategorized',
    };
  });
};

export const getAccountOptions = (accounts) => {
  return accounts.map((account) => {
    return {
      label: account.name,
      value: account.name,
    };
  });
};

export const getSliceOptions = (slices) => {
  if (slices.length === 0 || !slices) {
    return [];
  }
  const options = slices.map((slice) => {
    return {
      label: slice.name,
      value: slice.name,
    };
  });
  options.unshift({
    label: "Unassigned",
    value: null,
    // value: "Unassigned",
  });
  return options;
};

export const getCategoryOptions = (categories) => {
  if (!categories || categories.length === 0) {
    return [];
  }
  return categories.map((category) => {
    return {
      label: category.name,
      value: category.name,
    };
  });
};

// Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const passwordValidation = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);

export const formatDate = (dateString: string | undefined) => {
  if (!dateString) return "N/A";
  return new Date(dateString).toLocaleDateString();
};

export const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884D8",
    "#F06292",
    "#DCE775",
    "#82ca9d",
    "#ffc658",
    "#8dd1e1",
    "#a4de6c",
    "#d0ed57",
    "#FF6B6B",
    "#4ECDC4",
    "#45B7D1",
    "#FFA07A",
    "#98D8C8",
    "#AED581",
    "#FFD54F",
    "#4DB6AC",
    "#7986CB",
    "#9575CD",
    "#4DD0E1",
    "#81C784",
    "#FFB74D",
    "#F48FB1",
    "#A1887F",
    "#90A4AE",
    "#BA68C8",
    "#4FC3F7",
];

export const getFieldType = (field: string) => {
  const numberFields = [
    "amount",
    "deposit_amount",
    "slice_balance",
    "safe_to_spend_balance",
  ];
  const dateFields = ["date_range", "recurring_date"];
  const dropdownFields = ["category", "slices"];
  const stringFields = ["merchant_name", "deposit_frequency", "description"];

  if (numberFields.includes(field)) return "number";
  if (dateFields.includes(field)) return "date_range";
  if (dropdownFields.includes(field)) return "dropdown";
  if (stringFields.includes(field)) return "string";
};
