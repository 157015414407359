import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { z } from "zod";
import { USER_SIGN_IN } from "../mutations/userMutations";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { Input } from "./ui/Input";
import { updateCSRFToken } from "../graphqlProvider";
import { useNavigate } from "react-router";
import { useUser } from "../lib/UserContext";
import { Spinner } from "./ui/Spinner";
import { Button } from "./ui/Button";

const signInSchema = z.object({
  email: z
    .string()
    .email({ message: "Please enter a valid email address" })
    .min(1, { message: "Email is required" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters" }),
});

const UserSignIn = () => {
  const userContext = useUser();
  const [signIn, { data, loading, errors }] = useMutation(USER_SIGN_IN);
  const [postLoginProcessing, setPostLoginProcessing] = useState(false);

  const form = useForm({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      await signIn({ variables: values });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      (data && data.userSignIn && data.userSignIn.errors.length > 0) ||
      (errors && errors.length > 0)
    ) {
      toast.error("Invalid email or password");
    }

    if (data && data.userSignIn && data.userSignIn.user) {
      setPostLoginProcessing(true);
      toast.success("Signed in successfully");
      updateCSRFToken()
        .then(() => {
          userContext
            .updateUser(data.userSignIn.user)
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("error updating user", error);
              setPostLoginProcessing(false);
            });
        })
        .catch((error) => {
          console.log("error updating csrf token", error);
          setPostLoginProcessing(false);
        });
    }

    if (location.search.includes("confirmed=true")) {
      toast.success("Email confirmed successfully");
    }
  }, [data, errors]);

  if (loading || postLoginProcessing) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col justify-center px-6 py-12 lg:px-8">
      <div>
        <Toaster />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign In
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="email"
                className="grid gap-2"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="you@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="password"
                className="grid gap-2"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div>
              <Button
                type="submit"
                disabled={loading || postLoginProcessing}
                className="flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                variant="default"
              >
                {loading || postLoginProcessing ? "Signing in..." : "Sign in"}
              </Button>
            </div>
          </form>
        </Form>

        {/* <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?
          <br />
          <a
            href="/register"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Start a 14 day free trial
          </a>
        </p> */}
      </div>
    </div>
  );
};

export default UserSignIn;
