import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getOperationAST } from "graphql";

const csrfTokenElement = document.querySelector("meta[name=csrf-token]");
const csrfToken = csrfTokenElement
  ? csrfTokenElement.getAttribute("content")
  : "";

const introspectionLink = new ApolloLink((operation, forward) => {
  const operationAST = getOperationAST(
    operation.query,
    operation.operationName
  );
  if (
    operationAST &&
    operationAST.operation === "query" &&
    operationAST.name &&
    operationAST.name.value === "IntrospectionQuery"
  ) {
    return null;
  }
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: "/graphql",
  credentials: "include",
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    "X-CSRF-Token": csrfToken as string,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      console.error(`[GraphQL error]: Message: ${message}`);
      if (extensions?.code === "UNAUTHENTICATED") {
        window.location.href = "/login";
      }
    });
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    window.dispatchEvent(new Event("session-invalid"));
  }
});

export function updateCSRFToken() {
  return new Promise((resolve) => {
    const csrfTokenElement = document.querySelector("meta[name=csrf-token]");
    const csrfToken = csrfTokenElement
      ? csrfTokenElement.getAttribute("content")
      : "";
    setTimeout(() => {
      setContext((_, { headers }) => ({
        headers: {
          ...headers,
          "X-CSRF-Token": csrfToken as string,
        },
      }));
      resolve(csrfToken);
    }, 1000);
  });
}

export const client = new ApolloClient({
  link: ApolloLink.from([introspectionLink, authLink, httpLink, errorLink]),
  cache: new InMemoryCache(),
});
