import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PlaidLink from "./PlaidLink";
import PropTypes from "prop-types";
import { AlertCircleIcon } from "lucide-react";
import { Button } from "./ui/Button";

const PlaidModal = ({
  modalOpen,
  setModalOpen,
  needPlaidReconnection,
  setNeedPlaidReconnection,
  refetchPlaidStatus,
}) => {
  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <AlertCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {!needPlaidReconnection && (
                        <span>Connect your bank details with Plaid</span>
                      )}
                      {needPlaidReconnection && (
                        <span>Reconnect your bank details</span>
                      )}
                    </Dialog.Title>
                    <div className="mt-2">
                      {needPlaidReconnection && (
                        <p className="text-sm text-gray-500">
                          We need to reconnect your bank account to get the
                          latest data. Please click the button below to
                          reconnect.
                        </p>
                      )}
                      {!needPlaidReconnection && (
                        <p className="text-sm text-gray-500">
                          We use Plaid to pull your transaction data from your
                          bank for use within Cake Budget. We do not and will
                          never sell your data.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mt-5 sm:mt-6">
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setModalOpen(false);
                      setNeedPlaidReconnection(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <PlaidLink
                    setModalOpen={setModalOpen}
                    needPlaidReconnection={needPlaidReconnection}
                    refetchPlaidStatus={refetchPlaidStatus}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

PlaidModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  needPlaidReconnection: PropTypes.bool.isRequired,
  setNeedPlaidReconnection: PropTypes.func.isRequired,
  refetchPlaidStatus: PropTypes.func,
};

export default PlaidModal;
