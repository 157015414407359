import React from "react";
import PropTypes from "prop-types";
import DataTable from "./data-table";
import { transactionColumns } from "./data-table/transactionColumns";
import { serializeTransactions } from "../lib/utils";

const RecentTransactionsTable = ({
  transactions,
  refetchTransactions,
  setTransactions,
  accountOptions,
  accounts,
  categories,
  onRowClick,
  onEditTransaction,
  onBulkDelete,
  onBulkAssign,
  onBulkUnassign,
  onBulkChangeCategory,
  onBulkChangeDescription,
  slices,
}) => {
  const handleRefreshTransactions = async () => {
    try {
      const { data } = await refetchTransactions();
      const updatedTransactions = serializeTransactions(
        data.mostRecentTransactionsForUser,
        accounts
      );
      setTransactions(updatedTransactions);
    } catch (error) {
      console.error("Error refreshing transactions:", error);
    }
  };

  // Filter out the 'assignedSliceName' column
  // const filteredColumns = transactionColumns.filter(
  //   (column) => column.accessorKey !== "assignedSliceName"
  // );

  return (
    <div className="w-full">
      {transactions.length > 0 ? (
        <DataTable
          columns={transactionColumns}
          data={transactions}
          accountOptions={accountOptions}
          sliceOptions={slices.map((slice) => ({
            label: slice.name,
            value: slice.id,
          }))}
          refreshTransactions={handleRefreshTransactions}
          showingRecentTransactions={true}
          categories={categories}
          onRowClick={onRowClick}
          onEditTransaction={onEditTransaction}
          onBulkDelete={onBulkDelete}
          onBulkAssign={onBulkAssign}
          onBulkUnassign={onBulkUnassign}
          onBulkChangeCategory={onBulkChangeCategory}
          onBulkChangeDescription={onBulkChangeDescription}
          slices={slices}
        />
      ) : (
        <div className="text-center text-gray-500 py-4">
          No recent transactions available.
        </div>
      )}
    </div>
  );
};

RecentTransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      transactionDate: PropTypes.string.isRequired,
      transactionName: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      category: PropTypes.object,
      assignedSliceName: PropTypes.string,
      assignedSliceType: PropTypes.string,
      accountName: PropTypes.string.isRequired,
    })
  ).isRequired,
  refetchTransactions: PropTypes.func.isRequired,
  setTransactions: PropTypes.func.isRequired,
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEditTransaction: PropTypes.func,
  onRowClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func.isRequired,
  onBulkUnassign: PropTypes.func.isRequired,
  onBulkChangeCategory: PropTypes.func.isRequired,
  onBulkChangeDescription: PropTypes.func.isRequired,
  slices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RecentTransactionsTable;
