import React from "react";

const InstitutionLogo = ({ logo, name, className }) => {
  if (!logo) {
    return null;
  }

  return (
    <img
      src={`data:image/png;base64,${logo}`}
      alt={`${name} logo`}
      className={className}
    />
  );
};

export default InstitutionLogo;
