import { ColumnDef } from "@tanstack/react-table";
import { getRuleIntervalText, getRuleTypeText } from "../../lib/utils";

export const ruleColumns: ColumnDef[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "ruleCategory",
    header: "Type",
    cell: ({ row }) => {
      const rule = row.original;
      return getRuleTypeText(rule.ruleCategory);
    },
  },
  {
    accessorKey: "active",
    header: "Active",
    cell: ({ row }) => (row.getValue("active") ? "Yes" : "No"),
  },
  {
    accessorKey: "ruleSchedule.interval",
    header: "Interval",
    cell: ({ row }) => {
      const rule = row.original;
      return getRuleIntervalText(rule.ruleSchedule?.interval);
    },
  },
  {
    accessorKey: "ruleSchedule.nextRun",
    header: "Next Run",
    cell: ({ row }) => {
      const rule = row.original;
      const nextRun = rule.ruleSchedule?.nextRun;
      if (!nextRun) return "N/A";
      const date = new Date(nextRun as string);
      return date.toLocaleDateString();
    },
  },
];
