import { gql } from "@apollo/client";

const CREATE_RULE = gql`
  mutation createRule(
    $name: String!
    $ruleCategory: String!
    $active: Boolean!
    $envelopeId: ID
    $conditions: [RuleConditionInput!]!
    $actions: [RuleActionInput!]!
    $schedule: RuleScheduleInput!
  ) {
    createRule(
      input: {
        name: $name
        ruleCategory: $ruleCategory
        active: $active
        envelopeId: $envelopeId
        conditions: $conditions
        actions: $actions
        schedule: $schedule
      }
    ) {
      rule {
        id
        name
        ruleCategory
        active
        ruleConditions {
          id
          field
          operator
          value
        }
        ruleActions {
          id
          actionType
          parameters
        }
        ruleSchedule {
          id
          interval
          nextRun
        }
      }
      errors
    }
  }
`;

const UPDATE_RULE = gql`
  mutation updateRule(
    $id: ID!
    $name: String
    $ruleCategory: String
    $active: Boolean
    $envelopeId: ID
    $conditions: [RuleConditionInput!]
    $actions: [RuleActionInput!]
    $schedule: RuleScheduleInput
  ) {
    updateRule(
      input: {
        id: $id
        name: $name
        ruleCategory: $ruleCategory
        active: $active
        envelopeId: $envelopeId
        conditions: $conditions
        actions: $actions
        schedule: $schedule
      }
    ) {
      rule {
        id
        name
        ruleCategory
        active
        ruleConditions {
          id
          field
          operator
          value
        }
        ruleActions {
          id
          actionType
          parameters
        }
        ruleSchedule {
          id
          interval
          nextRun
        }
      }
      errors
    }
  }
`;

const DELETE_RULE = gql`
  mutation deleteRule($id: ID!) {
    deleteRule(input: { id: $id }) {
      success
      errors
    }
  }
`;

export { CREATE_RULE, UPDATE_RULE, DELETE_RULE };
