import { z } from "zod";

export const transactionSchema = z.object({
  id: z.string(),
  merchantName: z.string().nullable().optional(),
  amount: z.number(),
  transactionDate: z.string().optional(),
  pending: z.boolean().optional(),
  envelopeId: z.string().optional().nullable(),
  accountId: z.string().optional(),
  transactionName: z.string().optional(),
  assignedSliceName: z.string().optional().nullable(),
  isoCurrencyCode: z.string().optional(),
  assignedSliceType: z.string().optional().nullable(),
  merchantLogoUrl: z.string().optional().nullable(),
  category: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    icon: z.string().optional(),
  }).optional(),
  notes: z.string().nullable().optional(),
  accountName: z.string().optional(),
  status: z.enum(["pending", "posted"]).optional(),
});

export type Transaction = z.infer<typeof transactionSchema>;
