import React from "react";
import PropTypes from "prop-types";
import {
  AlertDialog,
  AlertDialogActionDestructive,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/AlertDialog";

const RemoveAccountModal = ({
  isOpen,
  onClose,
  onConfirm,
  name,
  type,
  isRemoving,
}) => {
  const itemType = type === "institution" ? "institution" : "account";
  const capitalizedType = itemType.charAt(0).toUpperCase() + itemType.slice(1);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Remove {capitalizedType}</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to remove the {itemType} &quot;{name}&quot;?
            This action cannot be undone.
            {type === "institution" && (
              <p className="mt-2 font-semibold">
                Note: Removing an institution will remove all associated
                accounts and transactions.
              </p>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose} disabled={isRemoving}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogActionDestructive
            onClick={onConfirm}
            disabled={isRemoving}
          >
            {isRemoving ? "Removing..." : "Remove"}
          </AlertDialogActionDestructive>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

RemoveAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["account", "institution"]).isRequired,
  isRemoving: PropTypes.bool.isRequired,
};

export default RemoveAccountModal;
