import { gql } from "@apollo/client";

const GET_ACCOUNTS_FOR_USER = gql`
  query getAccountsForUser {
    accountsForUser {
      id
      plaidAccountId
      name
      officialName
      subtype
      accountType
      availableBalance
      currentBalance
      isoCurrencyCode
      includedInSafeToSpend
      mask
      plaidItem {
        id
        institutionId
      }
    }
  }
`;

const GET_USER_PLAID_STATUS = gql`
  query getUserPlaidStatus {
    user {
      hasPlaidDetails
      needPlaidReconnection
      plaidLastSyncedAt
    }
  }
`;

const CHECK_PLAID_SYNC_STATUS = gql`
  query CheckPlaidSyncStatus($jobId: String!) {
    plaidSyncStatus(jobId: $jobId)
  }
`;

const GET_INSTITUTIONS = gql`
  query getInstitutions {
    institutions {
      id
      name
      logo
    }
  }
`;

export {
  GET_ACCOUNTS_FOR_USER,
  GET_USER_PLAID_STATUS,
  CHECK_PLAID_SYNC_STATUS,
  GET_INSTITUTIONS,
};
